import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Button, Modal, Table, Space, message, Input } from "antd";
import { Link } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  CloseSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";
import { RefillsTable } from "../../components";
import moment from "moment";

const Refills = () => {
  const [dataSource, setDataSource] = useState([]);
  const [deleteThis, setDeleteThis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [query, setQuery] = useState("");

  const pagePath = [
    {
      title: `Refills (${dataSource.length})`,
    },
  ];

  const handleDelete = () => {
    setDeleting(true);
    api
      .delete(`order/${deleteThis._id}`)
      .then(() => {
        setDataSource(dataSource.filter((s) => s._id !== deleteThis._id));
        message.success(
          `${deleteThis?.name || "Retailer"} deleted successfully`
        );
        setDeleteThis(null);
        setDeleting(false);
      })
      .catch(() => {
        message.error(`${deleteThis?.name || "Retailer"} could not be deleted`);
        setDeleting(false);
      });
  };
  const handleCancel = () => {
    setDeleteThis(null);
  };

  const columns = [
    // Table.EXPAND_COLUMN,
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (cell) => cell?.name || "",
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (cell) => cell?.name || "",
    },
    {
      title: "Brand",
      dataIndex: "product",
      render: (cell) => cell?.brand?.name || "",
    },
    // {
    //   title: "Total",
    //   dataIndex: "total",
    //   key: "total",
    //   render: (cell) => `$${cell}`,
    // },
    {
      title: "Placed on",
      dataIndex: "createdAt",
      render: (cell) => moment(cell).format("DD MMM YYYY hh:mm a"),
    },
    // {
    //   title: "No. of Subcategories",
    //   dataIndex: "subcategories",
    //   render: (cell) => cell?.length || 0,
    // },
    {
      title: "Actions",
      width: 110,
      render: (row) => (
        <Space>
          <Link to={`/order/${row._id}`}>
            <Button
              type="text"
              title="Edit"
              // disabled={loading || deleting}
              disabled
              icon={<EditOutlined style={{ color: "#66f" }} />}
            />
          </Link>
          <Button
            type="text"
            title="Delete"
            // disabled={loading || deleting}
            disabled
            onClick={() => {
              setDeleteThis(row);
            }}
            icon={<DeleteOutlined style={{ color: "#d66" }} />}
          />
        </Space>
      ),
    },
  ];
  useEffect(() => {
    setLoading(true);
    api
      .get("refills")
      .then((res) => {
        setDataSource(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err", err);
        setLoading(false);
      });
  }, []);
  const filtered =
    query.trim() === ""
      ? dataSource
      : dataSource.filter((d) =>
          d.name.toLowerCase().includes(query.toLowerCase())
        );
  return (
    <div id="orders">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/order/new">
            <Button type="primary">Add New</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Input
          autoFocus
          style={{ marginBottom: 12 }}
          placeholder="Search.."
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
        <Table
          dataSource={filtered}
          columns={columns}
          rowKey="_id"
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <CloseSquareOutlined
                  onClick={(e) => {
                    onExpand(record, e);
                  }}
                  style={{ color: "#e94b4c" }}
                />
              ) : (
                <PlusSquareOutlined
                  onClick={(e) => {
                    onExpand(record, e);
                  }}
                  style={{ color: "#7d7d7d" }}
                />
              ),
            expandedRowRender: (record) => {
              return (
                <div className="detailView">
                  <h3>Refill Details</h3>
                  <RefillsTable dataSource={record.refills} />
                </div>
              );
            },
          }}
        />
      </div>
      {deleteThis && (
        <Modal
          visible={!!deleteThis}
          onOk={handleDelete}
          onCancel={handleCancel}
        >
          Are you sure you want to delete {deleteThis?.name || "this order"}?
        </Modal>
      )}
    </div>
  );
};

export default React.memo(Refills);
