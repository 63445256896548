import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Tabs,
  Input,
  Select,
  Modal,
  Avatar,
  Space,
  Image,
  message,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";
import RestockForm from "./RestockForm";
import moment from "moment";
import {
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const { Meta } = Card;
const { confirm } = Modal;

const DispenserLogs = () => {
  // const { user, isLoggedIn } = store.useState((state) => state);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [pagePath, setPagePath] = useState([
    {
      title: `Dashboard`,
      link: "/",
    },
    {
      title: "Retailer",
    },
    {
      title: "Dispenser",
    },
    {
      title: "Logs",
    },
  ]);
  const { id } = params;
  // console.log(id);

  useEffect(() => {
    setLoading(true);
    api
      .get(`dashboard/logs/${id}`)
      .then((res) => {
        console.log(res.data.data);
        setDataSource(res.data.data);
        setPagePath(() => [
          {
            title: `Dashboard`,
            link: "/",
          },
          {
            title: res.data.trail?.retailer?.name || "Retailer",
            link: `/view/retailer/${res.data.trail?.retailer?._id}`,
          },
          {
            title: res.data.trail?.dispenser || "Dispenser",
            link: `/view/dispenser/${id}`,
          },
          {
            title: "Logs",
          },
        ]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err", err);
        setLoading(false);
      });
  }, [id]);

  const columns = [
    {
      title: "Entry",
      dataIndex: "m",
    },
    {
      title: "Timestamp",
      dataIndex: "t",
      render: (cell) =>
        cell ? moment(cell).format("DD MMM YYYY HH:mm a") : "",
    },
  ];

  return (
    <div id="dispenserView">
      <Breadcrumbs pagePath={pagePath} />
      <Table
        dataSource={dataSource}
        columns={columns}
        // pagination={false}
        style={{ marginTop: 30 }}
      />
    </div>
  );
};

export default React.memo(DispenserLogs);
