/* src/pages/dispensersets/NewDispenserset.jsx */
import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Card, Button, Form, Input, message, Select } from "antd";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";
const { Option } = Select;

const NewDispenserset = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [branches, setBranches] = useState([]);

  const filterOptions = (inputValue, option) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
        0
        ? true
        : false;
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        ? true
        : false;
    }
  };

  const pagePath = [
    {
      title: "Dispensersets",
      link: "/dispensersets",
    },
    {
      title: id ? "Edit Dispenserset" : "Add New Dispenserset",
    },
  ];

  const onChange = () => {};

  const onFinish = (values) => {
    const formData = JSON.parse(JSON.stringify(values));
    if (id) {
      handleUpdate(formData);
    } else {
      handleCreate(formData);
    }
  };

  const handleCreate = (data) => {
    setSubmitting(true);
    api
      .post("dispensersets", data)
      .then(() => {
        message.success("Dispenserset created successfully");
        navigate("/dispensersets");
      })
      .catch(() => {
        message.error("Dispenserset could not be created");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleUpdate = (data) => {
    setSubmitting(true);
    api
      .put(`dispensersets/${id}`, data)
      .then(() => {
        message.success("Dispenserset updated successfully");
        navigate("/dispensersets");
      })
      .catch(() => {
        message.error("Dispenserset could not be updated");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (id) {
      api
        .get(`dispensersets/${id}`)
        .then((res) => {
          const data = res.data.data;
          form.setFieldsValue({
            name: data?.name,
            slug: data?.slug,
            branch: data?.branch?._id,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id, form]);

  useEffect(() => {
    api
      .get("branches")
      .then((res) => {
        setBranches(res.data.data || []);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const normFile = (e) => {
    return e.fileList;
  };

  return (
    <div id="newDispenserset">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/dispensersets">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="dispenserset-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
            slug: "",
            branch: "",
          }}
          onValuesChange={onChange}
          onFinish={onFinish}
        >
          <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Form.Item
                label="Dispenserset Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Dispenserset name is required",
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label="Dispenserset Slug"
                name="slug"
                rules={[
                  {
                    required: true,
                    message: "Dispenserset slug is required",
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label="Branch"
                name="branch"
                rules={[
                  {
                    required: true,
                    message: "Please select a branch",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  // mode="multiple"
                  showSearch
                  filterOption={filterOptions}
                  placeholder="Select one"
                >
                  {branches.map((c) => (
                    <Option value={c._id} key={c._id}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {id ? "Update" : "Add"} Dispenserset
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(NewDispenserset);
