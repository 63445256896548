const config = {
  siteTitle: "ZeroTag",
  siteUrl: "localhost:3000",
  roles: [
    {
      id: 1,
      name: "Super Admin",
    },
    {
      id: 2,
      name: "Admin",
    },
    {
      id: 3,
      name: "Approver",
    },
    {
      id: 4,
      name: "User",
    },
  ],
};

export default config;
