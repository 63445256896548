import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  message,
  Select,
  Divider,
  Space,
  Upload,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";
import {
  BookOutlined,
  ContactsOutlined,
  CompassOutlined,
  CheckOutlined,
  PlusOutlined,
  PictureOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
const { Option } = Select;

const NewManufacturer = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [dbImages, setDbImages] = useState([]);
  const [dbImagesOrig, setDbImagesOrig] = useState([]);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  // console.log(fileList);
  const pagePath = [
    {
      title: "Manufacturers",
      link: "/manufacturers",
    },
    {
      title: id ? "Edit Manufacturer" : "Add New Manufacturer",
    },
  ];

  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const onChange = () => {};

  const onFinishFailed = ({ value, errorFields, outOfDate }) => {
    // console.log(value, errorFields, outOfDate);
    errorFields.map((e) => {
      e.errors.map((eI) => {
        message.error(eI);
      });
    });
  };

  const onFinish = (values) => {
    // console.log(values);
    const temp = JSON.parse(JSON.stringify(values));
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("images", file);
    });
    // formData.append("categories", JSON.stringify(temp.categories));
    formData.append("categories", JSON.stringify(temp?.categories));
    if (temp.paymentModes) {
      formData.append(
        "paymentModes",
        JSON.stringify(temp?.paymentModes) || "[]"
      );
    }
    // formData.append(
    //   "paymentModes",
    //   temp.paymentModes ? JSON.stringify(temp?.paymentModes) : []
    // );
    delete temp.categories;
    delete temp.images;
    delete temp.paymentModes;
    for (const name in temp) {
      if (temp[name]) {
        formData.append(name, temp[name]);
      }
    }
    const dbDeletions = dbImages
      .filter((d) => d.delete === true)
      .map((d) => d.path);
    if (dbDeletions.length) {
      formData.append("deletions", JSON.stringify(dbDeletions));
    }
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + "," + pair[1]);
    // }
    if (id) {
      handleUpdate(formData);
    } else {
      handleCreate(formData);
    }
  };

  const handleCreate = (data) => {
    // return;
    api
      .post("manufacturer", data)
      .then(() => {
        message.success("Manufacturer created successfully");
        navigate("/manufacturers");
      })
      .catch(() => {
        message.error("Manufacturer could not be created");
      });
  };

  const handleUpdate = (data) => {
    api
      .put(`manufacturer/${id}`, data)
      .then(() => {
        message.success("Manufacturer updated successfully");
        navigate("/manufacturers");
      })
      .catch(() => {
        message.error("Manufacturer could not be updated");
      });
  };

  const filterOptions = (inputValue, option) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
        0
        ? true
        : false;
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        ? true
        : false;
    }
  };

  useEffect(() => {
    if (id) {
      api
        .get(`manufacturer/${id}`)
        .then((res) => {
          console.log(res);
          if (res.data?.data) {
            form.setFieldsValue({
              name: res.data.data.name,
              description: res.data.data.description,
              categories: res.data.data.categories,
              year: res.data.data.year,
              tags: res.data.data.tags,
              phone: res.data.data.phone,
              phone2: res.data.data.phone2,
              email: res.data.data.email,
              website: res.data.data.website,
              address: res.data.data.address,
              latitude: res.data.data.latitude,
              longitude: res.data.data.longitude,
              status: res.data.data.status,
            });
            console.log(res.data.data.workingHours);
            if (res.data.data.images.length) {
              setDbImages(
                res.data.data.images.map((ig) => ({ path: ig, delete: false }))
              );
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [id]);

  useEffect(() => {
    Promise.all([api.get("categories/"), api.get("districts/")])
      .then((res) => {
        console.log("res", res);
        setCategories(res[0]?.data?.data);
        setDistricts(res[1]?.data?.data);
        // setLoading(false);
      })
      .catch((e) => {
        // setLoading(false);
        console.error(e);
      });
  }, []);
  const normFile = (e) => {
    // console.log("Upload event:", e);
    return e.fileList;

    // if (Array.isArray(e)) {
    //   return e;
    // }

    // return e && e.fileList;
    // return [...fileList, e.file];
    // return e.file;
    return fileList;
  };
  const props = {
    accept: "image/png, image/jpeg",
    listType: "picture-card",
    multiple: true,
    fileList: fileList,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      console.log(file);
      setFileList((prev) => [...prev, file]);
      return false;
    },
  };

  return (
    <div id="manufacturers">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/manufacturers">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="manufacturer-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
            status: true,
          }}
          onValuesChange={onChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Card style={{ marginBottom: 16 }}>
            <Divider orientation="left" orientationMargin="0">
              <Space align="center">
                <BookOutlined style={{ color: "#939397", fontSize: 18 }} />
                <h3>Basic Information</h3>
              </Space>
            </Divider>
            <div className="form-row">
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea showCount />
              </Form.Item>
              <Form.Item
                name="categories"
                label="Categories"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one category",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  mode="multiple"
                  showSearch
                  filterOption={filterOptions}
                  placeholder="Select.."
                >
                  {categories.map((c) => (
                    // <OptGroup label={c.name} key={c._id}>
                    //   {c.subcategories.map((sc) => (
                    <Option value={c._id} key={c._id}>
                      {c.name}
                    </Option>
                    //   ))}
                    // </OptGroup>
                  ))}
                </Select>
              </Form.Item>
              <Divider />
              <Form.Item label="Year Established" name="year">
                <Input />
              </Form.Item>
              <Form.Item label="Tags (Separated by commas)" name="tags">
                <Input.TextArea showCount />
              </Form.Item>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <PictureOutlined style={{ color: "#939397", fontSize: 20 }} />
                  <h3>Images</h3>
                </Space>
              </Divider>
              {!!dbImages.length && (
                <Row
                  gutter={[16, 16]}
                  style={{ marginBottom: 32 }}
                  wrap={true}
                  align="middle"
                  className="db-images"
                >
                  {dbImages.map((dbI, dbIdx) => (
                    <Col key={dbIdx} className="db-image">
                      {dbI.delete === true && (
                        <div className="del-wrap">
                          <DeleteOutlined />
                        </div>
                      )}
                      <Space
                        direction="vertical"
                        className={`${
                          dbI.delete === true ? "wrap-delete" : "wrap-normal"
                        }`}
                      >
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://api.zerotag.au/static/manufacturers/${dbI.path}`}
                        >
                          <img
                            src={`https://api.zerotag.au/static/manufacturers/${dbI.path}`}
                            height={120}
                          />
                        </a>
                        <div
                          className="btn-del"
                          onClick={() => {
                            setDbImages((prev) =>
                              prev.map((pI) =>
                                pI.path === dbI.path
                                  ? { ...pI, delete: !pI.delete }
                                  : pI
                              )
                            );
                          }}
                        >
                          Delete
                        </div>
                      </Space>
                    </Col>
                  ))}
                </Row>
              )}
              <Form.Item
                name="images"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                // extra="Supported: .jpf, .png"
              >
                <Upload {...props}>
                  <Button icon={<PlusOutlined />} />
                </Upload>
              </Form.Item>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <ContactsOutlined
                    style={{ color: "#939397", fontSize: 20 }}
                  />
                  <h3>Contact Information</h3>
                </Space>
              </Divider>
              <Form.Item
                label="Contact Person Name"
                name="person"
                rules={[
                  {
                    required: true,
                    message: "Contact person name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Primary Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Phone number is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Additional Phone Number" name="phone2">
                <Input />
              </Form.Item>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: false,
                    // message: "Email Address is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Website URL" name="website">
                <Input />
              </Form.Item>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <CompassOutlined style={{ color: "#939397", fontSize: 20 }} />
                  <h3>Location</h3>
                </Space>
              </Divider>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item label="Latitude" name="latitude">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Longitude" name="longitude">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <CheckOutlined style={{ color: "#939397", fontSize: 18 }} />
                  <h3>Publishing status</h3>
                </Space>
              </Divider>
              <Form.Item
                name="status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Select placeholder="Select one">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {id ? "Update" : "Add"} Manufacturer
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(NewManufacturer);
