import axios from "axios";

export const config = {
  baseURL: "https://api.zerotag.au/",
  // baseURL: "http://localhost:8000/",
  headers: {
    "Content-Type": "application/json",
  },
};

const API = () => {
  const getConfig = () => {
    // console.log(store.getRawState());
    if (api?.token) {
      return {
        ...config,
        baseURL: "https://api.zerotag.au/",
        // baseURL: "http://localhost:8000/",
        headers: {
          ...config.headers,
          Authorization: `Bearer ${api.token}`,
        },
      };
    }
    const token = document.cookie.replace(
      // eslint-disable-next-line no-useless-escape
      /(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return config;
  };
  return {
    get: async (url, config = {}) => {
      const res = await axios({ url, ...getConfig(), ...config });
      return res;
    },
    post: (url, data, config = {}) =>
      axios({ url, method: "POST", data, ...getConfig(), ...config }),
    postAuth: (url, data, config = {}) =>
      axios({ url, method: "POST", data, ...getConfig(), ...config }),
    put: (url, data, config) =>
      axios({ url, method: "PUT", data, ...getConfig(), ...config }),
    patch: (url, data, config) =>
      axios({ url, method: "PATCH", data, ...getConfig(), ...config }),
    delete: (url) => axios({ url, method: "DELETE", ...getConfig() }),
    setToken: (token) => {
      api.token = token;
      return api.token;
    },
    getToken: () => {
      return api.token;
    },
  };
};

const api = API();

export default api;
