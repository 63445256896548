import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Button, Modal, Table, Space, message } from "antd";
import { Link } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  CloseSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";
import TagDetails from "./TagDetail";

const Zerotags = () => {
  const [dataSource, setDataSource] = useState([]);
  const [deleteThis, setDeleteThis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [query, setQuery] = useState("");

  const pagePath = [
    {
      title: `Zerotags (${dataSource.length})`,
    },
  ];

  const updateTag = (id, data) => {
    setDataSource((prev) =>
      prev.map((p) => (p._id === id ? { ...p, ...data } : p))
    );
  };

  const handleDelete = () => {
    setDeleting(true);
    api
      .delete(`zerotag/${deleteThis._id}`)
      .then(() => {
        setDataSource(dataSource.filter((s) => s._id !== deleteThis._id));
        message.success(
          `${deleteThis?.name || "Zerotag"} deleted successfully`
        );
        setDeleteThis(null);
        setDeleting(false);
      })
      .catch(() => {
        message.error(`${deleteThis?.name || "Zerotag"} could not be deleted`);
        setDeleting(false);
      });
  };

  const handleCancel = () => {
    setDeleteThis(null);
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Variant",
      dataIndex: "variant",
      render: (row) => (
        <Row align="middle">
          <div
            className="swatch"
            style={
              row.scheme === "SOLID"
                ? { backgroundColor: row.color1 }
                : {
                    background: `linear-gradient(${row.color1}, ${row.color2})`,
                  }
            }
          />
          <p style={{ margin: 0 }}>{row?.name || ""}</p>
        </Row>
      ),
    },
    {
      title: "Assigned to",
      dataIndex: "user",
      render: (cell) => cell?.name || "",
    },
    {
      title: "Actions",
      width: 110,
      render: (row) => (
        <Space>
          <Link to={`/zerotag/${row._id}`}>
            <Button
              type="text"
              disabled={loading || deleting}
              icon={<EditOutlined style={{ color: "#66f" }} />}
            />
          </Link>
          <Button
            type="text"
            disabled={loading || deleting}
            onClick={() => {
              setDeleteThis(row);
            }}
            icon={<DeleteOutlined style={{ color: "#d66" }} />}
          />
        </Space>
      ),
    },
  ];
  useEffect(() => {
    setLoading(true);
    api
      .get("zerotags")
      .then((res) => {
        setDataSource(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err2", err);
        setLoading(false);
      });
  }, []);

  const filtered =
    query.trim() === ""
      ? dataSource
      : dataSource.filter((d) =>
          d.code.toLowerCase().includes(query.toLowerCase())
        );

  return (
    <div id="zerotags">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Space direction="horizontal">
            <Link to="/zerotag/new">
              <Button type="primary">Add New</Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <div className="body">
        <Table
          dataSource={filtered}
          columns={columns}
          rowKey="_id"
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <CloseSquareOutlined
                  onClick={(e) => {
                    onExpand(record, e);
                  }}
                  style={{ color: "#e94b4c" }}
                />
              ) : (
                <PlusSquareOutlined
                  onClick={(e) => {
                    onExpand(record, e);
                  }}
                  style={{ color: "#7d7d7d" }}
                />
              ),
            expandedRowRender: (record) => {
              return <TagDetails id={record._id} updater={updateTag} />;
            },
          }}
        />
      </div>
      {deleteThis && (
        <Modal
          visible={!!deleteThis}
          onOk={handleDelete}
          onCancel={handleCancel}
        >
          Are you sure you want to delete {deleteThis?.name || "this zerotag"}?
        </Modal>
      )}
    </div>
  );
};

export default React.memo(Zerotags);
