import { Tabs } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { HistoryTable, Loader } from "../../components";
import { api } from "../../lib";
import QREmbed from "./QREmbed";
const { TabPane } = Tabs;
const DispenserDetail = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState(null);

  useEffect(() => {
    if (id) {
      setLoading(true);
      api
        .get(`dispenser/${id}`)
        .then((res) => {
          if (res.data?.data) {
            setRecord(res.data.data);
          }
        })
        .catch(() => {
          // console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="detailWrap">
      <Tabs defaultActiveKey="history" centered>
        <TabPane tab="History" key="history">
          <HistoryTable dataSource={record?.logs || []} />
        </TabPane>
        <TabPane tab="Code" key="code">
          <QREmbed value={record?.code || null} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DispenserDetail;
