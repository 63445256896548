import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Card, Button, Form, Input, message, Select } from "antd";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Colorpicker, ColorPickerValue } from "antd-colorpicker";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";
const { Option } = Select;

const NewVariant = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [scheme, setScheme] = useState("SOLID");
  const [color1, setColor1] = useState({ hex: "#212121" });
  const [color2, setColor2] = useState({ hex: "#d2d2d2" });
  const [color2Visible, setColor2Visible] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  // console.log(fileList);
  const pagePath = [
    {
      title: "Variants",
      link: "/variants",
    },
    {
      title: id ? "Edit Variant" : "Add New Variant",
    },
  ];

  const onChange = (values) => {
    console.log("Changed", values);
    if (values.scheme === "LINEAR_GRADIENT") {
      setScheme(values.scheme);
      setColor2Visible(true);
    } else if (values.scheme === "SOLID") {
      setScheme(values.scheme);
      setColor2Visible(false);
    }
    if (values.color1) {
      setColor1(values.color1);
    }
    if (values.color2) {
      setColor2(values.color2);
    }
  };

  const onFinishFailed = ({ errorFields }) => {
    // console.log(value, errorFields, outOfDate);
    errorFields.map((e) => {
      e.errors.map((eI) => {
        message.error(eI);
      });
    });
  };

  const handleCreate = (values) => {
    setSubmitting(true);
    if (values.color1) {
      values.color1 = values.color1.hex;
    }
    if (values.color2) {
      values.color2 = values.color2.hex;
    }
    api
      .post("variant", values)
      .then(() => {
        message.success("Variant created successfully");
        navigate("/variants");
      })
      .catch((e) => {
        message.error("Variant could not be created");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleUpdate = (values) => {
    console.log(values);
    setSubmitting(true);
    if (values.color1) {
      values.color1 = values.color1.hex;
    }
    if (values.color2) {
      values.color2 = values.color2.hex;
    }
    api
      .put(`variant/${id}`, values)
      .then(() => {
        message.success("Variant updated successfully");
        navigate("/variants");
      })
      .catch(() => {
        message.error("Variant could not be updated");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (id) {
      api
        .get(`variant/${id}`)
        .then((res) => {
          console.log(res);
          if (res.data?.data) {
            form.setFieldsValue({
              name: res.data.data.name,
              scheme: res.data.data.scheme,
              color1: { hex: res.data.data.color1 },
              color2: { hex: res.data.data.color2 },
            });
            setScheme(res.data.data.scheme);
            setColor1({ hex: res.data.data.color1 });
            setColor2({ hex: res.data.data.color2 });
            if (res.data.data.scheme === "LINEAR_GRADIENT") {
              setColor2Visible(true);
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id, form]);

  return (
    <div id="variants">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/variants">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="variant-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
            scheme: "SOLID",
            color1: color1,
            color2: color2,
            // color2: { hex: "#FFFFFF" },
            // color2: null,
          }}
          onValuesChange={onChange}
          onFinish={id ? handleUpdate : handleCreate}
          // onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Row gutter={32} align="middle">
                <Col span={12}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Variant name is required",
                      },
                    ]}
                  >
                    <Input autoFocus />
                  </Form.Item>
                  <Form.Item
                    label="Scheme"
                    name="scheme"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select placeholder="Select one">
                      <Option value="SOLID">Solid</Option>
                      <Option value="LINEAR_GRADIENT">Linear Gradient</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Color 1"
                    name="color1"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Colorpicker popup />
                  </Form.Item>
                  {color2Visible && (
                    <Form.Item
                      label="Color 2"
                      name="color2"
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                    >
                      <Colorpicker popup />
                    </Form.Item>
                  )}
                </Col>
                <Col span={12}>
                  <div
                    className="preview"
                    style={{
                      background:
                        scheme === "SOLID"
                          ? color1.hex
                          : `linear-gradient(${color1.hex}, ${color2.hex})`,
                    }}
                  >
                    <div className="preview-inner">
                      <p>preview</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {id ? "Update" : "Add"} Variant
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(NewVariant);
