import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Button,
  Modal,
  Table,
  Space,
  message,
  Input,
  Card,
  Form,
  Avatar,
  Select,
  Divider,
  Upload,
} from "antd";
import { Link, useParams } from "react-router-dom";
import Icon, {
  CaretUpOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  LeftOutlined,
  PlusOutlined,
  MinusOutlined,
  FileImageOutlined,
  MinusCircleOutlined,
  StarOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";
import { HistoryTable } from "../../components";
// import { pick } from "lodash";
const { Search } = Input;
const { Meta } = Card;

const RetailerProducts = () => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [retailer, setRetailer] = useState(null);
  const [products, setProducts] = useState([]);
  const { id } = useParams();

  const pagePath = [
    {
      title: "Retailers",
      link: "/retailers",
    },
    {
      title: "Products",
    },
  ];

  // const filtered =
  //   query.trim() === ""
  //     ? dataSource
  //     : dataSource.filter((d) =>
  //         d.name.toLowerCase().includes(query.toLowerCase())
  //       );

  const columns = [
    {
      // title: "Name",
      // dataIndex: "name",
      // key: "name",
      render: (row) => (
        <Space direction="horizontal" size="middle">
          {row?.packagingStill ? (
            <Avatar
              size={48}
              src={`https://api.zerotag.au/static/products/${row.packagingStill}`}
            />
          ) : (
            <div className="placeholderCircle" />
          )}
          <span>{row.name}</span>
        </Space>
      ),
    },
    // {
    //   dataIndex: "packagingStill",
    // },
    // {
    //   title: "No. of Subcategories",
    //   dataIndex: "subcategories",
    //   render: (cell) => cell?.length || 0,
    // },
    {
      // title: "Actions",
      width: 110,
      render: (row) => (
        <Space>
          <Button
            // type="primary"
            title="Select"
            disabled={loading}
            onClick={() => {
              doAssign(row._id, false);
            }}
            icon={<StarOutlined />}
          >
            Assign
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (id) {
      setLoading(true);
      api
        .get(`retailerproducts/${id}`)
        .then((res) => {
          // console.log(res);
          setRetailer(res.data.data);
          setProducts(res.data.products);
          setLoading(false);
        })
        .catch((err) => {
          console.error("err", err);
          setLoading(false);
        });
    }
  }, [id]);

  const doAssign = (product, remove) => {
    // console.log("args", product, remove);
    const data = {
      retailer: id,
      product: product,
      remove,
    };
    api
      .put(`retailerproduct`, data)
      .then((res) => {
        console.log("RES", res);
        message.success("Product assigned successfully");
        const temp = JSON.parse(JSON.stringify(retailer));
        temp.products = res.data.data;
        setRetailer(temp);
        // navigate("/zerotags");
      })
      .catch(() => {
        message.error("Product could not be assigned");
      });
  };

  const productIds = retailer?.products?.map((p) => p._id);

  const cleaned =
    productIds?.length > 0
      ? products.filter((p) => !productIds.includes(p._id))
      : products;
  const filtered =
    query.trim() === ""
      ? cleaned
      : cleaned.filter((d) =>
          d.name.toLowerCase().includes(query.toLowerCase())
        );

  // console.log("Q", query);

  // console.log(products, filtered, retailer.products);

  return (
    <div id="retailerProducts">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Space direction="horizontal">
            {/* <Button onClick={saveBlocks}>Import</Button> */}
            <Link to="/retailers">
              <Button>Cancel</Button>
            </Link>
            {/* <Button onClick={saveBlocks} type="primary">
              Save
            </Button> */}
          </Space>
        </Col>
      </Row>
      <div className="body">
        <Card className="cardDark">
          <h3 style={{ marginBottom: 8, display: "block" }}>ASSIGNED</h3>
          {retailer?.products?.length ? (
            <Row gutter={16}>
              {retailer.products.map((p, pI) => (
                <Col xl={4} lg={6} md={8} sm={12} xs={24} key={pI}>
                  <Card
                    style={{ width: "100%" }}
                    cover={
                      <img
                        alt="example"
                        src={`https://api.zerotag.au/static/products/${p.packagingStill}`}
                      />
                    }
                    on
                    actions={[
                      // <SettingOutlined key="setting" />,
                      // <EditOutlined key="edit" />,
                      <DeleteOutlined
                        key="unassign"
                        style={{ color: "#cc8888" }}
                        onClick={() => {
                          // console.log("b4", p._id);
                          doAssign(p._id, true);
                        }}
                      />,
                    ]}
                  >
                    <Meta
                      // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                      title={p.name}
                      // description={`$${p.price} / ${p.unit}`}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <div className="empty">
              {retailer?.name || "This retailer"} has no products.
            </div>
          )}
        </Card>
        <Card style={{ marginTop: 20 }}>
          <h3>Other Products</h3>
          <Search
            placeholder="Search.."
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            allowClear
            style={{ marginTop: 16, marginBottom: 8 }}
          />
          <Table
            rowKey="_id"
            dataSource={filtered}
            showHeader={false}
            columns={columns}
          />
        </Card>
      </div>
    </div>
  );
};

export default React.memo(RetailerProducts);
