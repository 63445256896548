import React from "react";
import { Table } from "antd";
import moment from "moment";
import "./style.scss";

const RefillsTable = ({ dataSource }) => {
  if (dataSource.length < 1) {
    return "no entries";
  }
  return (
    <div style={{ width: "80%", margin: "0 10%" }}>
      <Table
        dataSource={dataSource}
        rowKey="_id"
        columns={[
          {
            title: "Product",
            dataIndex: "product",
            render: (cell) => cell?.name || "-",
          },
          {
            title: "Unit",
            render: (cell) => cell?.product?.unit || "-",
          },
          {
            title: "Qty",
            dataIndex: "qty",
          },
          {
            title: "Price",
            dataIndex: "total",
          },
          {
            title: "User",
            dataIndex: "user",
            render: (cell) => cell?.name || "-",
          },
          {
            title: "Refilled on",
            dataIndex: "t",
            render: (cell) => moment(cell).format("DD MMM YYYY hh:mm a"),
          },
        ]}
        pagination={false}
      />
    </div>
  );
};

export default React.memo(RefillsTable);
