import React, { useState } from "react";
import "./style.scss";
import { Row, Col, Card, Button, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api, store } from "../../lib";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const { user } = store.useState((state) => state);
  const id = user?._id;

  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const pagePath = [
    {
      title: "Change Password",
      link: "/account",
    },
  ];

  const onChange = () => {};

  const onFinish = (values) => {
    const formData = JSON.parse(JSON.stringify(values));
    if (id) {
      handleUpdate(formData);
    }
  };
  const handleUpdate = (data) => {
    if (passwordsMatch) {
      setSubmitting(true);
      api
        .put(`user/changepass/${id}`, data)
        .then(() => {
          message.success("Password changed successfully");
          navigate("/account");
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            message.error(error.response.data.message);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      message.error("New password and Retype password do not match");
    }
    return false;
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    // Check if the passwords match whenever the new password changes
    setPasswordsMatch(e.target.value === retypePassword);
  };

  const handleRetypePasswordChange = (e) => {
    setRetypePassword(e.target.value);
    // Check if the passwords match whenever the retype password changes
    setPasswordsMatch(e.target.value === newPassword);
  };

  return (
    <div id="newBrand">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="change-pwd-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
            slug: "",
          }}
          onValuesChange={onChange}
          onFinish={onFinish}
        >
          <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Form.Item
                label="Current Password"
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: "Current password is required",
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                onChange={handleNewPasswordChange}
                rules={[
                  {
                    required: true,
                    message: "New password is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Retype Password"
                name="retypePassword"
                onChange={handleRetypePasswordChange}
                rules={[
                  {
                    required: true,
                    message: "Retype above password",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(ChangePassword);
