import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  message,
  Select,
  TimePicker,
  Divider,
  Space,
  Upload,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";
import {
  BookOutlined,
  ContactsOutlined,
  CompassOutlined,
  FieldTimeOutlined,
  CheckOutlined,
  PictureOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import moment from "moment";
const { Option } = Select;
const defaultHours = [
  { status: "open", from: "09:00", to: "18:00" },
  { status: "open", from: "09:00", to: "18:00" },
  { status: "open", from: "09:00", to: "18:00" },
  { status: "open", from: "09:00", to: "18:00" },
  { status: "open", from: "09:00", to: "18:00" },
  { status: "open", from: "09:00", to: "18:00" },
  { status: "closed", from: "09:00", to: "18:00" },
];

const NewRetailer = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [hours, setHours] = useState(defaultHours);
  const [fileList, setFileList] = useState([]);
  const [dbImages, setDbImages] = useState([]);

  const [showBasic, setShowBasic] = useState(true);
  const [showImages, setShowImages] = useState(true);
  const [showContact, setShowContact] = useState(true);
  const [showLocation, setShowLocation] = useState(true);
  const [showHours, setShowHours] = useState(true);

  const navigate = useNavigate();
  const { id } = useParams();

  const pagePath = [
    {
      title: "Retailers",
      link: "/retailers",
    },
    {
      title: id ? "Edit Retailer" : "Add New Retailer",
    },
  ];

  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((e) => {
      e.errors.map((eI) => {
        message.error(eI);
      });
    });
  };

  const onTimeChange = (e, f, index, target) => {
    console.log(e, f, index, target);
    if (e) {
      const temp = JSON.parse(JSON.stringify(hours));
      temp[index][target] = f;
      setHours(temp);
    }
  };

  const onFinish = (values) => {
    const temp = JSON.parse(JSON.stringify(values));
    const formData = new FormData();
    if (fileList.length) {
      formData.append("image", fileList[0]);
    }
    delete temp.image;
    // formData.append("categories", JSON.stringify(temp?.categories));
    if (temp.paymentModes) {
      formData.append(
        "paymentModes",
        JSON.stringify(temp?.paymentModes) || "[]"
      );
    }
    // delete temp.categories;
    delete temp.paymentModes;
    for (const name in temp) {
      if (temp[name]) {
        formData.append(name, temp[name]);
      }
    }
    formData.append("workingHours", JSON.stringify(hours));
    if (id) {
      handleUpdate(formData);
    } else {
      handleCreate(formData);
    }
  };

  const handleCreate = (data) => {
    api
      .post("retailer", data)
      .then(() => {
        message.success("Retailer created successfully");
        navigate("/retailers");
      })
      .catch(() => {
        message.error("Retailer could not be created");
      });
  };

  const handleUpdate = (data) => {
    api
      .put(`retailer/${id}`, data)
      .then(() => {
        message.success("Retailer updated successfully");
        navigate("/retailers");
      })
      .catch(() => {
        message.error("Retailer could not be updated");
      });
  };

  const onHoursChange = (e, index) => {
    const temp = JSON.parse(JSON.stringify(hours));
    temp[index].status = e;
    setHours(temp);
  };

  useEffect(() => {
    if (id) {
      api
        .get(`retailer/${id}`)
        .then((res) => {
          if (res.data?.data) {
            form.setFieldsValue({
              name: res.data.data.name,
              slug: res.data.data.slug,
              description: res.data.data.description,
              about: res.data.data.about,
              year: res.data.data.year,
              paymentModes: res.data.data.paymentModes,
              tags: res.data.data.tags,
              phone: res.data.data.phone,
              phone2: res.data.data.phone2,
              email: res.data.data.email,
              website: res.data.data.website,
              address: res.data.data.address,
              image: res.data.data.image
                ? [
                    {
                      url: `https://api.zerotag.au/static/retailers/${
                        res.data.data?.image || ""
                      }`,
                      name: res.data.data?.name,
                      uid: 0,
                    },
                  ]
                : [],
              // district: res.data.data.district,
              mapLink: res.data.data.mapLink,
              latitude: res.data.data.latitude,
              longitude: res.data.data.longitude,
              status: res.data.data.status,
            });
            setHours(JSON.parse(res.data?.data?.workingHours) || defaultHours);
            if (res.data.data.images.length) {
              setDbImages(
                res.data.data.images.map((ig) => ({ path: ig, delete: false }))
              );
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [id]);

  const normFile = (e) => {
    return e.fileList;
  };

  const props = {
    accept: "image/png, image/jpeg",
    listType: "picture-card",
    multiple: true,
    fileList: fileList,
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
  };

  return (
    <div id="newRetailer">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/retailers">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="retailer-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
            status: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Card style={{ marginBottom: 16 }}>
            <Divider orientation="left" orientationMargin="0">
              <Space align="center">
                <BookOutlined style={{ color: "#939397", fontSize: 18 }} />
                <h3>Basic Information</h3>
              </Space>
              <div className="absRight">
                <Button
                  onClick={() => {
                    setShowBasic(!showBasic);
                  }}
                  icon={showBasic ? <CaretUpOutlined /> : <CaretDownOutlined />}
                />
              </div>
            </Divider>
            <div className="form-row">
              <Form.Item
                label="Retailer Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Retailer name is required",
                  },
                ]}
                hidden={!showBasic}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label="URL friendly slug"
                name="slug"
                rules={[
                  {
                    required: true,
                    message: "slug is required",
                  },
                ]}
                hidden={!showBasic}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: false,
                  },
                ]}
                hidden={!showBasic}
              >
                <Input.TextArea showCount />
              </Form.Item>
              <Form.Item
                hidden={!showBasic}
                label="About the company"
                name="about"
              >
                <Input.TextArea showCount />
              </Form.Item>
              <Form.Item
                hidden={!showBasic}
                label="Year Established"
                name="year"
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden={!showBasic}
                label="Payment Modes"
                name="paymentModes"
              >
                <Select mode="multiple" placeholder="Select..">
                  <Option value="Cash">Cash</Option>
                  <Option value="Card">Card</Option>
                  <Option value="UPI">UPI</Option>
                </Select>
              </Form.Item>
              <Form.Item
                hidden={!showBasic}
                label="Tags (Separated by commas)"
                name="tags"
              >
                <Input.TextArea showCount />
              </Form.Item>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <PictureOutlined style={{ color: "#939397", fontSize: 20 }} />
                  <h3>Logo</h3>
                </Space>
                <div className="absRight">
                  <Button
                    onClick={() => {
                      setShowImages(!showImages);
                    }}
                    icon={
                      showImages ? <CaretUpOutlined /> : <CaretDownOutlined />
                    }
                  />
                </div>
              </Divider>
              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                // extra="Supported: .jpf, .png"
              >
                <Upload {...props}>
                  {fileList.length < 1 && <Button icon={<PictureOutlined />} />}
                </Upload>
              </Form.Item>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <ContactsOutlined
                    style={{ color: "#939397", fontSize: 20 }}
                  />
                  <h3>Contact Information</h3>
                </Space>
                <div className="absRight">
                  <Button
                    onClick={() => {
                      setShowContact(!showContact);
                    }}
                    icon={
                      showContact ? <CaretUpOutlined /> : <CaretDownOutlined />
                    }
                  />
                </div>
              </Divider>
              <Form.Item
                label="Primary Phone Number"
                name="phone"
                rules={[
                  {
                    required: false,
                  },
                ]}
                hidden={!showContact}
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden={!showContact}
                label="Additional Phone Number"
                name="phone2"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: false,
                  },
                ]}
                hidden={!showContact}
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden={!showContact}
                label="Website URL"
                name="website"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: false,
                  },
                ]}
                hidden={!showContact}
              >
                <Input.TextArea />
              </Form.Item>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <CompassOutlined style={{ color: "#939397", fontSize: 20 }} />
                  <h3>Location</h3>
                </Space>
                <div className="absRight">
                  <Button
                    onClick={() => {
                      setShowLocation(!showLocation);
                    }}
                    icon={
                      showLocation ? <CaretUpOutlined /> : <CaretDownOutlined />
                    }
                  />
                </div>
              </Divider>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    hidden={!showLocation}
                    label="Latitude"
                    name="latitude"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hidden={!showLocation}
                    label="Longitude"
                    name="longitude"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    hidden={!showLocation}
                    label="Google Maps Link"
                    name="mapLink"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <FieldTimeOutlined
                    style={{ color: "#939397", fontSize: 20 }}
                  />
                  <h3>Working Hours</h3>
                </Space>
                <div className="absRight">
                  <Button
                    onClick={() => {
                      setShowHours(!showHours);
                    }}
                    icon={
                      showHours ? <CaretUpOutlined /> : <CaretDownOutlined />
                    }
                  />
                </div>
              </Divider>
              {showHours && (
                <Row className="hours-wrap">
                  {hours.map((hr, hrI) => (
                    <Col span={6} className="hours-item" key={hrI}>
                      <h4>{weekDays[hrI]}</h4>
                      <Select
                        value={hr?.status}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          onHoursChange(e, hrI);
                        }}
                      >
                        <Option value="open">Open From-to</Option>
                        <Option value="24h">Open 24h</Option>
                        <Option value="closed">Closed</Option>
                      </Select>
                      {hr?.status === "open" && (
                        <div className="timepickers">
                          <TimePicker
                            format="HH:mm"
                            minuteStep={5}
                            allowClear="false"
                            placeholder="From"
                            value={moment(hr.from, "HH:mm")}
                            onChange={(e, f) => {
                              onTimeChange(e, f, hrI, "from");
                            }}
                          />
                          <TimePicker
                            format="HH:mm"
                            minuteStep={5}
                            allowClear="false"
                            placeholder="To"
                            value={moment(hr.to, "HH:mm")}
                            onChange={(e, f) => {
                              onTimeChange(e, f, hrI, "to");
                            }}
                          />
                        </div>
                      )}
                      {hr?.status === "24h" && (
                        <span className="text-success">Open 24 hours</span>
                      )}
                      {hr?.status === "closed" && (
                        <span className="text-danger">Closed all day</span>
                      )}
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <CheckOutlined style={{ color: "#939397", fontSize: 18 }} />
                  <h3>Publishing status</h3>
                </Space>
              </Divider>
              <Form.Item
                name="status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Select placeholder="Select one">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {id ? "Update" : "Add"} Retailer
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(NewRetailer);
