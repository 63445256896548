import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  message,
  Select,
  Upload,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";
import { PictureOutlined } from "@ant-design/icons";
const { Option } = Select;

const NewDesign = () => {
  const [form] = Form.useForm();
  const [fileListIcon, setFileListIcon] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const pagePath = [
    {
      title: "Designs",
      link: "/designs",
    },
    {
      title: id ? "Edit Design" : "Add New Design",
    },
  ];

  const onChange = () => {};

  const onFinish = (values) => {
    const temp = JSON.parse(JSON.stringify(values));
    const formData = new FormData();
    if (fileListIcon.length) {
      formData.append("image", fileListIcon[0]);
    }
    delete temp.image;
    for (const name in temp) {
      if (temp[name]) {
        formData.append(name, temp[name]);
      }
    }
    if (id) {
      handleUpdate(formData);
    } else {
      handleCreate(formData);
    }
  };

  const handleCreate = (data) => {
    setSubmitting(true);
    api
      .post("design", data)
      .then(() => {
        message.success("Design created successfully");
        navigate("/designs");
      })
      .catch(() => {
        message.error("Design could not be created");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleUpdate = (data) => {
    setSubmitting(true);
    api
      .put(`design/${id}`, data)
      .then(() => {
        message.success("Design updated successfully");
        navigate("/designs");
      })
      .catch(() => {
        message.error("Design could not be updated");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (id) {
      api
        .get(`design/${id}`)
        .then((res) => {
          form.setFieldsValue({
            name: res.data.data?.name,
            image: res.data.data.image
              ? [
                  {
                    url: `https://api.zerotag.au/static/designs/${
                      res.data.data?.image || ""
                    }`,
                    name: res.data.data?.name,
                    uid: 0,
                  },
                ]
              : [],
            status: res.data.data?.status,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id, form]);

  const checkImageDimension = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl;

        image.addEventListener("load", () => {
          const { width, height } = image;
          if (width !== height) {
            message.error("Please upload an image with square dimensions");
            resolve(Upload.LIST_IGNORE);
          }
          resolve(false);
        });
      });
    });
  };

  const propsIcon = {
    accept: "image/png, image/jpeg",
    listType: "picture-card",
    multiple: false,
    fileList: fileListIcon,
    onRemove: () => {
      setFileListIcon([]);
    },
    beforeUpload: checkImageDimension,
  };

  const normFile = (e) => {
    if (e.fileList.length) {
      setFileListIcon([e.fileList[0]?.originFileObj]);
    }
    return e.fileList;
  };

  return (
    <div id="newDesign">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/designs">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="design-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
            status: true,
          }}
          onValuesChange={onChange}
          onFinish={onFinish}
        >
          <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Form.Item
                label="Design Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Design name is required",
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Select name="status" placeholder="Select one">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                label="Icon"
                // extra="Supported: .jpf, .png"
                style={{ marginBottom: 0 }}
              >
                <Upload {...propsIcon}>
                  {fileListIcon.length < 1 && (
                    <Button icon={<PictureOutlined />} />
                  )}
                </Upload>
              </Form.Item>
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {id ? "Update" : "Add"} Design
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(NewDesign);
