import React, { useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { api, store } from "../../../lib";

const LoginForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    api
      .postAuth("auth/login/", {
        username: values.username,
        password: values.password,
      })
      .then((res) => {
        // console.log(res);
        api.setToken(res.data.token);
        document.cookie = `AUTH_TOKEN=${res.data.token}; expires=604800; path=/`;
        store.update((s) => {
          s.isLoggedIn = true;
          s.user = res.data.data;
          s.token = res.data.token;
        });
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 400 || err.status === 404) {
          setLoading(false);
          message.error(err.data.data);
        }
      });
  };

  return (
    <Form
      name="login-form"
      layout="vertical"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        label="Username"
        rules={[
          {
            required: true,
            message: "Please input your Username!",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        disabled={loading}
        rules={[
          {
            required: true,
            message: "Please input your Password!",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          disabled={loading}
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        {/* <Link className="login-form-forgot" to="/forgot-password">
          Forgot password
        </Link> */}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
