import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  message,
  Select,
  Divider,
  Space,
  Upload,
  Switch,
  InputNumber,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";
import {
  BookOutlined,
  ContactsOutlined,
  CompassOutlined,
  CheckOutlined,
  GifOutlined,
  AudioOutlined,
  PictureOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const { Dragger } = Upload;

const NewProduct = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [instructions, setInstructions] = useState([]);

  const [fileListStill, setFileListStill] = useState([]);
  const [fileListAnim, setFileListAnim] = useState([]);
  const [fileListAudio, setFileListAudio] = useState([]);
  const [fileListAus, setFileListAus] = useState([]);
  const [fileListPreg, setFileListPreg] = useState([]);
  const [fileListStar, setFileListStar] = useState([]);
  const [fileListInstr, setFileListInstr] = useState([]);

  const [dbPackagingStill, setDbPackagingStill] = useState(null);
  const [dbPackagingAnim, setDbPackagingAnim] = useState(null);
  const [dbSonicBranding, setDbSonicBranding] = useState(null);
  const [dbMadeInAus, setDbMadeInAus] = useState(null);
  const [dbPregWarning, setDbPregWarning] = useState(null);
  const [dbHealthStar, setDbHealthStar] = useState(null);
  const [replaceStill, setReplaceStill] = useState(false);
  const [replaceAnim, setReplaceAnim] = useState(false);
  const [replaceSonic, setReplaceSonic] = useState(false);
  const [replaceAus, setReplaceAus] = useState(false);
  const [replacePreg, setReplacePreg] = useState(false);
  const [replaceStar, setReplaceStar] = useState(false);

  const [madeInAus, setMadeInAus] = useState(false);
  const [madeInAusCustom, setMadeInAusCustom] = useState(false);

  const [pregWarning, setPregWarning] = useState(false);
  const [pregWarningCustom, setPregWarningCustom] = useState(false);

  const [healthStar, setHealthStar] = useState(false);
  const [healthStarCustom, setHealthStarCustom] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  // console.log(fileList);
  const pagePath = [
    {
      title: "Products",
      link: "/products",
    },
    {
      title: id ? "Edit Product" : "Add New Product",
    },
  ];

  const onChange = (changed, values) => {
    console.log(values.instructions);
    if ("madeInAus" in changed) {
      setMadeInAus(changed.madeInAus);
    }
    if ("pregnancyWarning" in changed) {
      setPregWarning(changed.pregnancyWarning);
    }
    if ("healthStar" in changed) {
      setHealthStar(changed.healthStar);
    }
  };

  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((e) => {
      e.errors.map((eI) => {
        message.error(eI);
      });
    });
  };

  const onFinish = (values) => {
    const temp = JSON.parse(JSON.stringify(values));
    const formData = new FormData();
    if (form.getFieldValue("instructions")) {
      const tempInstr = form.getFieldValue("instructions");
      const newInstr = [];
      tempInstr.map((ti) => {
        if (ti.body && ti.body?.trim() !== "") {
          newInstr.push({
            body: ti.body,
            img: ti.img?.length > 0 ? ti.img[0].name : null,
          });
          if (ti.img?.length > 0) {
            formData.append("instrImages", ti.img[0].originFileObj);
          }
        }
      });
      delete temp.instructions;
      formData.append("instructions", JSON.stringify(newInstr));
    }
    // console.log(formData);

    if (fileListStill.length) {
      formData.append("packagingStill", fileListStill[0]);
    }
    if (fileListAnim.length) {
      formData.append("packagingAnim", fileListAnim[0]);
    }
    if (fileListAudio.length) {
      formData.append("sonicBranding", fileListAudio[0]);
    }
    if (madeInAus && !madeInAusCustom) {
      formData.append("delMadeInAusImg", true);
    } else if (fileListAus.length) {
      formData.append("madeInAusImg", fileListAus[0]);
    }
    if (pregWarning && !pregWarningCustom) {
      formData.append("delPregnancyWarningImg", true);
    } else if (fileListPreg.length) {
      formData.append("pregnancyWarningImg", fileListPreg[0]);
    }
    if (healthStar && !healthStarCustom) {
      formData.append("delHealthStarImg", true);
    } else if (fileListStar.length) {
      formData.append("healthStarImg", fileListStar[0]);
    }
    delete temp.packagingStill;
    delete temp.packagingAnim;
    delete temp.sonicBrandingTemp;
    delete temp.madeInAusImgTemp;
    delete temp.pregnancyWarningImgTemp;
    delete temp.healthStarImgTemp;
    for (const name in temp) {
      if (temp[name]) {
        formData.append(name, temp[name]);
      }
    }
    // console.log(formData);
    // return;
    if (id) {
      handleUpdate(formData);
    } else {
      handleCreate(formData);
    }
  };

  const handleCreate = (data) => {
    // return;
    setSubmitting(true);
    api
      .post("product", data)
      .then(() => {
        message.success("Product created successfully");
        navigate("/products");
      })
      .catch(() => {
        message.error("Product could not be created");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleUpdate = (data) => {
    setSubmitting(true);
    api
      .put(`product/${id}`, data)
      .then(() => {
        message.success("Product updated successfully");
        navigate("/products");
      })
      .catch(() => {
        message.error("Product could not be updated");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (id) {
      api
        .get(`product/${id}`)
        .then((res) => {
          console.log(res);
          if (res.data?.data) {
            form.setFieldsValue({
              name: res.data.data.name,
              category: res.data.data.category,
              about: res.data.data.about,
              website: res.data.data.website,
              brand: res.data.data.brand,
              status: res.data.data.status,
              altText: res.data.data.altText,
              sonicBranding: res.data.data.sonicBranding,
              vegetarian: res.data.data.vegetarian,
              vegan: res.data.data.vegan,
              glutenFree: res.data.data.glutenFree,
              halal: res.data.data.halal,
              alcohol: res.data.data.alcohol,
              dairy: res.data.data.dairy,
              shellfish: res.data.data.shellfish,
              eggs: res.data.data.eggs,
              beef: res.data.data.beef,
              caffeine: res.data.data.caffeine,
              kosher: res.data.data.kosher,
              freeRange: res.data.data.freeRange,
              organic: res.data.data.organic,
              sesame: res.data.data.sesame,
              nuts: res.data.data.nuts,
              citrus: res.data.data.citrus,
              pork: res.data.data.pork,

              madeInAus: res.data.data.madeInAus,
              healthStar: res.data.data.healthStar,
              healthStarVal: res.data.data.healthStarVal,
              pregnancyWarning: res.data.data.pregnancyWarning,
              ingredients: res.data.data.ingredients,
              allergenWarning: res.data.data.allergenWarning,
              instructions: res.data.data.cookingInstructions
                ? res.data.data.cookingInstructions.map((ci, ciI) => ({
                    body: ci.body,
                    img: ci.img
                      ? [
                          {
                            url: `https://api.zerotag.au/static/products/${ci.img}`,
                            name: ci.img,
                            uid: ciI,
                          },
                        ]
                      : null,
                  }))
                : [],
              cookingLink: res.data.data.cookingLink,
            });
            setMadeInAus(res.data.data.madeInAus);
            setPregWarning(res.data.data.pregnancyWarning);
            setHealthStar(res.data.data.healthStar);

            if (res.data.data.packagingStill) {
              setDbPackagingStill(res.data.data.packagingStill);
            }
            if (res.data.data.packagingAnim) {
              setDbPackagingAnim(res.data.data.packagingAnim);
            }
            if (res.data.data.sonicBranding) {
              setDbSonicBranding(res.data.data.sonicBranding);
            }
            if (res.data.data.madeInAusImg) {
              setDbMadeInAus(res.data.data.madeInAusImg);
              setMadeInAusCustom(true);
            }
            if (res.data.data.pregnancyWarningImg) {
              setDbPregWarning(res.data.data.pregnancyWarningImg);
              setPregWarningCustom(true);
            }
            if (res.data.data.healthStarImg) {
              setDbHealthStar(res.data.data.healthStarImg);
              setHealthStarCustom(true);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [id]);

  useEffect(() => {
    Promise.all([
      api.get("constants/categories/"),
      api.get("constants/brands/"),
    ])
      .then((res) => {
        // console.log("ret", res[0]?.data?.data, res[1]?.data?.data);
        setCategories(res[0]?.data?.data || []);
        setBrands(res[1]?.data?.data || []);
        // setLoading(false);
      })
      .catch((e) => {
        // setLoading(false);
        console.error(e);
      });
  }, []);

  const normFile = (e) => {
    return e.fileList;
  };

  const propsStill = {
    accept: "image/png, image/jpeg",
    listType: "picture-card",
    multiple: false,
    fileList: fileListStill,
    onRemove: () => {
      setFileListStill([]);
    },
    beforeUpload: (file) => {
      setFileListStill([file]);
      return false;
    },
  };
  const propsAnim = {
    // .svg, .gif, .css, .mov., .mp4
    accept: "image/gif, image/svg, video/mp4, video/quicktime, text/css",
    listType: "picture-card",
    multiple: false,
    fileList: fileListAnim,
    onRemove: () => {
      setFileListAnim([]);
    },
    beforeUpload: (file) => {
      setFileListAnim([file]);
      return false;
    },
  };
  const propsAudio = {
    // .svg, .gif, .css, .mov., .mp4
    accept: "audio/*",
    listType: "picture",
    multiple: false,
    fileList: fileListAudio,
    onRemove: () => {
      setFileListAudio([]);
    },
    beforeUpload: (file) => {
      setFileListAudio([file]);
      return false;
    },
  };
  const propsAus = {
    accept: "image/png, image/jpeg",
    listType: "picture-card",
    multiple: false,
    fileList: fileListAus,
    onRemove: () => {
      setFileListAus([]);
    },
    beforeUpload: (file) => {
      setFileListAus([file]);
      return false;
    },
  };
  const propsPreg = {
    accept: "image/png, image/jpeg",
    listType: "picture-card",
    multiple: false,
    fileList: fileListPreg,
    onRemove: () => {
      setFileListPreg([]);
    },
    beforeUpload: (file) => {
      setFileListPreg([file]);
      return false;
    },
  };
  const propsStar = {
    accept: "image/png, image/jpeg",
    listType: "picture-card",
    multiple: false,
    fileList: fileListStar,
    onRemove: () => {
      setFileListStar([]);
    },
    beforeUpload: (file) => {
      setFileListStar([file]);
      return false;
    },
  };

  const instrFile = (e) => {
    console.log("iF", e);
    return e.fileList;
    // return [e.fileList[e.fileList.length - 1]];
  };

  const propsInstr = {
    accept: "image/png, image/jpeg",
    listType: "picture-card",
    multiple: false,
    // maxCount: 1,
    showUploadList: {
      showDownloadIcon: false,
    },
    fileList: fileListInstr,
    beforeUpload: (file) => {
      console.log("b4", file, fileListInstr);
      setFileListInstr([file]);
      return false;
    },
    // onChange: ({ fileList }) => {
    //   console.log("onChange", fileList, fileListInstr);
    //   setFileListInstr([fileList[fileList.length - 1]]);
    // },
    onRemove: (file) => {
      console.log("onRemove", fileListInstr, file);
      // const index = fileListInstr.indexOf(file);
      // const newFileList = fileListInstr.slice();
      // newFileList.splice(index, 1);
      // setFileListInstr(newFileList);
      // setFileListInstr([]);
    },
  };

  useEffect(() => {
    console.log(fileListInstr);
  }, [fileListInstr]);

  const propsInstr0 = (pos) => ({
    // height: 320,
    // width: 200,
    // style: {
    //   backgroundColor: "#aaa3",
    //   color: "#fff",
    //   borderWidth: 0,
    //   backgroundImage: `url(${instructions[pos].img})`,
    //   backgroundSize: "cover",
    // },
    listType: "picture-card",
    // onPreview: handlePreview,
    name: "file",
    multiple: false,
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange: async (info) => {
      // console.log("change", info, info.file?.status || "-", fileList);
      if (!info.file.url && !info.file.preview) {
        const previewImg = await getBase64(info.fileList[0].originFileObj);
        // console.log(previewImg);
        const temp = [...instructions];
        temp[pos].img = previewImg;
        // setPreviewImage(previewImg);
        setInstructions(temp);
      }
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log("uploading", info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove: (file) => {
      const index = fileListInstr.indexOf(file);
      const newFileList = fileListInstr.slice();
      newFileList.splice(index, 1);
      setFileListInstr(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
  });

  const filterOptions = (inputValue, option) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
        0
        ? true
        : false;
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        ? true
        : false;
    }
  };

  const addInstr = () => {
    setInstructions([
      ...instructions,
      {
        img: null,
        body: "",
      },
    ]);
  };

  const deleteInstr = (index) => {
    const temp = [...instructions];
    temp.splice(index, 1);
    setInstructions(temp);
  };

  const instrUp = (index) => {
    const temp = [...instructions];
    const a = temp[index - 1];
    temp[index - 1] = temp[index];
    temp[index] = a;
    setInstructions(temp);
  };

  const instrDown = (index) => {
    const temp = [...instructions];
    const a = temp[index + 1];
    temp[index + 1] = temp[index];
    temp[index] = a;
    setInstructions(temp);
  };

  return (
    <div id="newproduct">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/products">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="product-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
            status: true,
          }}
          onValuesChange={onChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Card style={{ marginBottom: 16 }}>
            <Divider orientation="left" orientationMargin="0">
              <Space align="center">
                <BookOutlined style={{ color: "#939397", fontSize: 18 }} />
                <h3>Basic Information</h3>
              </Space>
            </Divider>
            <div className="form-row">
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="category"
                    label="Category"
                    rules={[
                      {
                        required: true,
                        message: "Please select a product category",
                      },
                    ]}
                    // hidden={!showBasic}
                  >
                    <Select
                      style={{ width: "100%" }}
                      // mode="multiple"
                      showSearch
                      filterOption={filterOptions}
                      placeholder="Select one"
                    >
                      {categories.map((c) => (
                        // <OptGroup label={c.name} key={c._id}>
                        //   {c.subcategories.map((sc) => (
                        <Option value={c._id} key={c._id}>
                          {c.name}
                        </Option>
                        //   ))}
                        // </OptGroup>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="brand"
                    label="Brand"
                    rules={[
                      {
                        required: true,
                        message: "Please select a brand",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      // mode="multiple"
                      allowClear
                      showSearch
                      // filterOption={filterOptions}
                      placeholder="Select.."
                    >
                      {brands.map((c) => (
                        // <OptGroup label={c.name} key={c._id}>
                        //   {c.subcategories.map((sc) => (
                        <Option value={c._id} key={c._id}>
                          {c.name}
                        </Option>
                        //   ))}
                        // </OptGroup>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="About"
                name="about"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea showCount />
              </Form.Item>
              <Form.Item
                label="Product Website"
                name="website"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <PictureOutlined style={{ color: "#939397", fontSize: 20 }} />
                  <h3>Assets</h3>
                </Space>
              </Divider>
              <Row gutter={16}>
                <Col span={12}>
                  <p>Packaging (Still)</p>
                  <div>
                    {!!dbPackagingStill && !replaceStill && (
                      <a
                        style={{ display: "block", marginBottom: 8 }}
                        target="_blank"
                        rel="noreferrer"
                        href={`https://api.zerotag.au/static/products/${dbPackagingStill}`}
                      >
                        <img
                          src={`https://api.zerotag.au/static/products/${dbPackagingStill}`}
                          height={104}
                        />
                      </a>
                    )}
                    {(dbPackagingStill === null ||
                      (!!dbPackagingStill && replaceStill)) && (
                      <Form.Item
                        name="packagingStill"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        // extra="Supported: .jpf, .png"
                        style={{ marginBottom: 0 }}
                      >
                        <Upload {...propsStill}>
                          {fileListStill.length < 1 && (
                            <Button icon={<PictureOutlined />} />
                          )}
                        </Upload>
                      </Form.Item>
                    )}
                    {!!dbPackagingStill && (
                      <Button
                        danger={replaceStill}
                        onClick={() => {
                          setReplaceStill(!replaceStill);
                        }}
                        style={{ marginTop: 3 }}
                      >
                        {replaceStill ? "Cancel" : "Replace"}
                      </Button>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <p>Packaging (Animated)</p>
                  <div>
                    {!!dbPackagingAnim && !replaceAnim && (
                      <a
                        style={{ display: "block", marginBottom: 8 }}
                        target="_blank"
                        rel="noreferrer"
                        href={`https://api.zerotag.au/static/products/${dbPackagingAnim}`}
                      >
                        <img
                          src={`https://api.zerotag.au/static/products/${dbPackagingAnim}`}
                          height={104}
                        />
                      </a>
                    )}
                    {(dbPackagingAnim === null ||
                      (!!dbPackagingAnim && replaceAnim)) && (
                      <Form.Item
                        name="packagingAnim"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        // extra="Supported: .jpf, .png"
                        style={{ marginBottom: 0 }}
                      >
                        <Upload {...propsAnim}>
                          {fileListAnim.length < 1 && (
                            <Button icon={<GifOutlined />} />
                          )}
                        </Upload>
                      </Form.Item>
                    )}
                    {!!dbPackagingAnim && (
                      <Button
                        danger={replaceAnim}
                        onClick={() => {
                          setReplaceAnim(!replaceAnim);
                        }}
                        style={{ marginTop: 3 }}
                      >
                        {replaceAnim ? "Cancel" : "Replace"}
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              <Divider />
              <p>Sonic Branding</p>
              <div style={{ marginBottom: 28 }}>
                {!!dbSonicBranding && !replaceSonic && (
                  <div className="audioPlayer">
                    <audio controls>
                      <source
                        src={`https://api.zerotag.au/static/products/${dbSonicBranding}`}
                      />
                    </audio>
                    <div className="filename">{dbSonicBranding}</div>
                  </div>
                )}
                {(dbSonicBranding === null ||
                  (!!dbSonicBranding && replaceSonic)) && (
                  <Form.Item
                    name="sonicBrandingTemp"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    // extra="Supported: .jpf, .png"
                    style={{ marginBottom: 0 }}
                  >
                    <Upload {...propsAudio} className="audioUpload">
                      {fileListAudio.length < 1 && (
                        <>
                          <Button
                            className="audioUploadBtn"
                            icon={<AudioOutlined />}
                          >
                            Select file (mp3/wav/ogg)
                          </Button>
                        </>
                      )}
                    </Upload>
                  </Form.Item>
                )}
                {!!dbSonicBranding && (
                  <Button
                    danger={replaceSonic}
                    onClick={() => {
                      setReplaceSonic(!replaceSonic);
                    }}
                    style={{ marginTop: 8 }}
                  >
                    {replaceSonic ? "Cancel" : "Replace"}
                  </Button>
                )}
              </div>
              <Divider />
              <Form.Item
                label="Alt Text"
                name="altText"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input.TextArea showCount />
              </Form.Item>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }} className="dietary">
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <ContactsOutlined
                    style={{ color: "#939397", fontSize: 20 }}
                  />
                  <h3>Dietary Labels</h3>
                </Space>
              </Divider>
              <Row gutter={16} className="dietaryRow">
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Vegetarian"
                    name="vegetarian"
                    className="labelGreen"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Vegan"
                    name="vegan"
                    className="labelGreen"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Gluten-Free"
                    name="glutenFree"
                    className="labelGreen"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Halal"
                    name="halal"
                    className="labelRed"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="dietaryRow">
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Alcohol"
                    name="alcohol"
                    className="labelGreen"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Dairy"
                    name="dairy"
                    className="labelRed"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Shellfish"
                    name="shellfish"
                    className="labelRed"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Eggs"
                    name="eggs"
                    className="labelRed"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="dietaryRow">
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Beef"
                    name="beef"
                    className="labelRed"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Caffeine"
                    name="caffeine"
                    className="labelGreen"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Kosher"
                    name="kosher"
                    className="labelRed"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Free-Range"
                    name="freeRange"
                    className="labelRed"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="dietaryRow">
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Organic"
                    name="organic"
                    className="labelGreen"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Sesame"
                    name="sesame"
                    className="labelGreen"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Nuts"
                    name="nuts"
                    className="labelGreen"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Citrus"
                    name="citrus"
                    className="labelGreen"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} className="dietaryRow">
                <Col span={6}>
                  <Form.Item
                    valuePropName="checked"
                    label="Pork"
                    name="pork"
                    className="labelRed"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <CompassOutlined style={{ color: "#939397", fontSize: 20 }} />
                  <h3>MISC</h3>
                </Space>
              </Divider>
              <Row gutter={12}>
                <Col span={12}>
                  <div className="ctrlGroup">
                    <h3>Made in Australia</h3>
                    <Form.Item
                      name="madeInAus"
                      valuePropName="checked"
                      label="Show Label"
                      className="labelSm"
                    >
                      <Switch />
                    </Form.Item>
                    {madeInAus && (
                      <>
                        <p className="mbSm">Use Custom Image</p>
                        <Switch
                          onChange={(e) => {
                            setMadeInAusCustom(e);
                          }}
                          checked={madeInAusCustom}
                        />
                      </>
                    )}
                    {madeInAus && madeInAusCustom && (
                      <div className="imgOverride">
                        {!!dbMadeInAus && !replaceAus && (
                          <a
                            style={{ display: "block", marginBottom: 8 }}
                            target="_blank"
                            rel="noreferrer"
                            href={`https://api.zerotag.au/static/products/${dbMadeInAus}`}
                          >
                            <img
                              src={`https://api.zerotag.au/static/products/${dbMadeInAus}`}
                              height={104}
                            />
                          </a>
                        )}
                        {(dbMadeInAus === null ||
                          (!!dbMadeInAus && replaceAus)) && (
                          <Form.Item
                            name="madeInAusImgTemp"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            // extra="Supported: .jpf, .png"
                            style={{ marginBottom: 0 }}
                          >
                            <Upload {...propsAus}>
                              {fileListAus.length < 1 && (
                                <Button icon={<PictureOutlined />} />
                              )}
                            </Upload>
                          </Form.Item>
                        )}
                        {!!dbMadeInAus && (
                          <Button
                            danger={replaceAus}
                            onClick={() => {
                              setReplaceAus(!replaceAus);
                            }}
                            style={{ marginTop: 3 }}
                          >
                            {replaceAus ? "Cancel" : "Replace"}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="ctrlGroup">
                    <h3>Pregnancy Warning</h3>
                    <Form.Item
                      name="pregnancyWarning"
                      valuePropName="checked"
                      label="Show Label"
                      className="labelSm"
                    >
                      <Switch />
                    </Form.Item>
                    {pregWarning && (
                      <>
                        <p className="mbSm">Use Custom Image</p>
                        <Switch
                          onChange={(e) => {
                            setPregWarningCustom(e);
                          }}
                          checked={pregWarningCustom}
                        />
                      </>
                    )}
                    {pregWarning && pregWarningCustom && (
                      <div className="imgOverride">
                        {!!dbPregWarning && !replacePreg && (
                          <a
                            style={{ display: "block", marginBottom: 8 }}
                            target="_blank"
                            rel="noreferrer"
                            href={`https://api.zerotag.au/static/products/${dbPregWarning}`}
                          >
                            <img
                              src={`https://api.zerotag.au/static/products/${dbPregWarning}`}
                              height={104}
                            />
                          </a>
                        )}
                        {(dbPregWarning === null ||
                          (!!dbPregWarning && replacePreg)) && (
                          <Form.Item
                            name="pregnancyWarningImgTemp"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            // extra="Supported: .jpf, .png"
                            style={{ marginBottom: 0 }}
                          >
                            <Upload {...propsPreg}>
                              {fileListPreg.length < 1 && (
                                <Button icon={<PictureOutlined />} />
                              )}
                            </Upload>
                          </Form.Item>
                        )}
                        {!!dbPregWarning && (
                          <Button
                            danger={replacePreg}
                            onClick={() => {
                              setReplacePreg(!replacePreg);
                            }}
                            style={{ marginTop: 3 }}
                          >
                            {replacePreg ? "Cancel" : "Replace"}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <Row gutter={12} style={{ marginTop: 20 }}>
                <Col span={12}>
                  <div className="ctrlGroup">
                    <h3>Health Stars</h3>
                    <Form.Item
                      name="healthStar"
                      valuePropName="checked"
                      label="Show Label"
                      className="labelSm"
                    >
                      <Switch />
                    </Form.Item>
                    {healthStar && (
                      <>
                        <Form.Item label="Rating" name="healthStarVal">
                          <InputNumber controls={true} min={0} max={5} />
                        </Form.Item>
                        <p className="mbSm">Use Custom Image</p>
                        <Switch
                          onChange={(e) => {
                            setHealthStarCustom(e);
                          }}
                          checked={healthStarCustom}
                        />
                      </>
                    )}
                    {healthStar && healthStarCustom && (
                      <div className="imgOverride">
                        {!!dbHealthStar && !replaceStar && (
                          <a
                            style={{ display: "block", marginBottom: 8 }}
                            target="_blank"
                            rel="noreferrer"
                            href={`https://api.zerotag.au/static/products/${dbHealthStar}`}
                          >
                            <img
                              src={`https://api.zerotag.au/static/products/${dbHealthStar}`}
                              height={104}
                            />
                          </a>
                        )}
                        {(dbHealthStar === null ||
                          (!!dbHealthStar && replaceStar)) && (
                          <Form.Item
                            name="healthStarImgTemp"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            // extra="Supported: .jpf, .png"
                            style={{ marginBottom: 0 }}
                          >
                            <Upload {...propsStar}>
                              {fileListStar.length < 1 && (
                                <Button icon={<PictureOutlined />} />
                              )}
                            </Upload>
                          </Form.Item>
                        )}
                        {!!dbHealthStar && (
                          <Button
                            danger={replaceStar}
                            onClick={() => {
                              setReplaceStar(!replaceStar);
                            }}
                            style={{ marginTop: 3 }}
                          >
                            {replaceStar ? "Cancel" : "Replace"}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Ingredients"
                    name="ingredients"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input.TextArea showCount rows={4} />
                  </Form.Item>
                  <Form.Item
                    label="Allergen Warning"
                    name="allergenWarning"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input.TextArea showCount rows={4} />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row gutter={12} style={{ marginBottom: 12, marginTop: 620 }}>
                <Col span={12}>
                  <Form.Item
                    name="packagingAus"
                    valuePropName="fileList"
                    label="Made In Australia Label"
                    getValueFromEvent={normFile}
                    // extra="Supported: .jpf, .png"
                    style={{ marginBottom: 0 }}
                  >
                    <Upload {...propsAus}>
                      {fileListAus.length < 1 && (
                        <Button icon={<PictureOutlined />} />
                      )}
                    </Upload>
                  </Form.Item>
                  {/* <Form.Item
                    valuePropName="checked"
                    label="Made In Australia?"
                    name="madeInAus"
                  >
                    <Switch />
                  </Form.Item> 
                </Col>
                <Col span={12}>
                  <Form.Item label="Health Star Rating" name="healthStar">
                    <InputNumber controls={true} min={0} max={5} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label="Pregnancy Warning"
                    name="pregnancyWarning"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input.TextArea showCount />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={12}>
                  
                </Col>
              </Row> */}
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <CompassOutlined style={{ color: "#939397", fontSize: 20 }} />
                  <h3>Cooking Instructions</h3>
                </Space>
              </Divider>
              <div className="instructions">
                <Form.List name="instructions">
                  {(fields, { add, remove, move }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 20,
                            // border: "solid red",
                          }}
                          align="baseline"
                        >
                          <div>
                            <div>
                              <Button
                                icon={
                                  <CaretUpOutlined
                                    style={{
                                      color: index < 1 ? "#ccccdd" : "#9999af",
                                    }}
                                  />
                                }
                                disabled={index < 1}
                                onClick={() => {
                                  console.log("mUp", key, fields.length);
                                  move(index, index - 1);
                                }}
                              />
                            </div>
                            <div>
                              <Button
                                icon={
                                  <CaretDownOutlined
                                    style={{
                                      color:
                                        index >= fields.length - 1
                                          ? "#ccccdd"
                                          : "#9999af",
                                    }}
                                  />
                                }
                                disabled={index >= fields.length - 1}
                                onClick={() => {
                                  move(index, index + 1);
                                }}
                              />
                            </div>
                          </div>
                          <Form.Item
                            {...restField}
                            name={[name, "img"]}
                            style={{ marginBottom: 0 }}
                            valuePropName="fileList"
                            getValueFromEvent={instrFile}
                            // rules={[
                            //   { required: true, message: "Missing last name" },
                            // ]}
                          >
                            <Upload maxCount={1} {...propsInstr}>
                              {/* {!fileListInstr[index] && ( */}
                              {/* {fileListInstr.length < 1 && ( */}
                              <Button icon={<PictureOutlined />} />
                              {/* )} */}
                            </Upload>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "body"]}
                            style={{ marginBottom: 0 }}
                            // rules={[
                            //   { required: true, message: "Missing last name" },
                            // ]}
                          >
                            <Input.TextArea />
                          </Form.Item>
                          <MinusCircleOutlined
                            style={{ color: "#ff4d4f" }}
                            onClick={() => remove(name)}
                          />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add item
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                {/* {instructions.length < 1 ? (
                  <div className="empty">No instructions defined</div>
                ) : (
                  <div>
                    {instructions.map((i, iI) => (
                      <Row key={iI} className="instrRow">
                        <Col className="instrMove">
                          <div>
                            <Button
                              onClick={() => {
                                instrUp(iI);
                              }}
                              type="link"
                              style={{ color: iI === 0 ? "#ddd" : "#aaa" }}
                              icon={
                                <CaretUpOutlined style={{ fontSize: 16 }} />
                              }
                              disabled={iI === 0}
                            />
                          </div>
                          <div>
                            <Button
                              onClick={() => {
                                instrDown(iI);
                              }}
                              type="link"
                              style={{
                                color:
                                  iI >= instructions.length - 1
                                    ? "#ddd"
                                    : "#aaa",
                              }}
                              disabled={iI >= instructions.length - 1}
                              icon={
                                <CaretDownOutlined style={{ fontSize: 16 }} />
                              }
                            />
                          </div>
                        </Col>
                        <Col className="instrBody">
                          {/* <Form.Item
                            name="packagingStill"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            // extra="Supported: .jpf, .png"
                          >  <Upload {...propsInstr(iI)}>
                            {fileListInstr.length < 1 && (
                              <Button icon={<PictureOutlined />} />
                            )}
                          </Upload> 

                          <Dragger {...propsInstr(iI)}>
                            {instructions[iI].img && (
                              <>
                                <p className="ant-upload-drag-icon">
                                  <PictureOutlined style={{ fontSize: 40 }} />
                                </p>
                                <p
                                  className="ant-upload-hint"
                                  style={{ color: "#2d6597" }}
                                >
                                  Select Image
                                </p>
                              </>
                            )}
                          </Dragger>
                          {/* </Form.Item> 
                        </Col>
                        <Col className="instrBody" flex={1}>
                          <Input.TextArea
                            value={i.body}
                            onChange={(e) => {
                              const temp = [...instructions];
                              temp[iI].body = e.target.value;
                              setInstructions(temp);
                            }}
                            rows={4}
                          />
                        </Col>
                        <Col className="instrBody">
                          <Button
                            type="text"
                            disabled={loading}
                            onClick={() => {
                              deleteInstr(iI);
                            }}
                            icon={<DeleteOutlined style={{ color: "#d66" }} />}
                          />
                        </Col>
                      </Row>
                    ))}
                  </div>
                )} */}
                {/* <Button className="instrAdd" onClick={addInstr}>
                  Add Item
                </Button> */}
              </div>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label="Youtube Link"
                    name="cookingLink"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <CheckOutlined style={{ color: "#939397", fontSize: 18 }} />
                  <h3>Publishing status</h3>
                </Space>
              </Divider>
              <Form.Item
                name="status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Select placeholder="Select one">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {id ? "Update" : "Add"} Product
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(NewProduct);
