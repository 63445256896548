import React from "react";
import { Table } from "antd";
import moment from "moment";
import "./style.scss";

const HistoryTable = ({ dataSource }) => {
  if (dataSource.length < 1) {
    return "no entries";
  }
  return (
    <div
    // style={{ width: "80%", margin: "0 10%" }}
    >
      <Table
        dataSource={dataSource}
        rowKey="t"
        columns={[
          {
            title: "Message",
            dataIndex: "m",
          },
          {
            title: "Date",
            dataIndex: "t",
            render: (cell) => moment(cell).format("DD MMM YYYY hh:mm a"),
          },
        ]}
        pagination={false}
      />
    </div>
  );
};

export default React.memo(HistoryTable);
