import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Card, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { api } from "../../lib";
import { Breadcrumbs, Loader } from "../../components";

const pagePath = [
  {
    title: `Dashboard`,
  },
];

const Home = () => {
  // const { user, isLoggedIn } = store.useState((state) => state);
  const [retailers, setRetailers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    api
      .get("dashboard/main")
      .then((res) => {
        setRetailers(res.data.retailers || []);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err", err);
        setLoading(false);
      });
  }, []);

  return (
    <div id="home">
      {/* <h1>{`Welcome back${isLoggedIn ? ", " : ""}${user?.name}`}</h1> */}
      <Breadcrumbs pagePath={pagePath} />
      {loading ? (
        <Loader />
      ) : (
        <div>
          {retailers.length ? (
            <Row gutter={[16, 16]} style={{ marginTop: 32 }}>
              {retailers.map((r, rI) => (
                <Col span={12} key={rI}>
                  <Card
                    hoverable={true}
                    onClick={() => {
                      navigate(`/view/retailer/${r._id}`);
                    }}
                  >
                    <Row>
                      <Col span={8}>
                        {r.image ? (
                          <img
                            style={{ width: "80%" }}
                            src={`https://api.zerotag.au/static/retailers/${r.image}`}
                          />
                        ) : (
                          <div className="avatar-placeholder" />
                        )}
                      </Col>
                      <Col span={16}>
                        <h1 style={{ marginBottom: 5 }}>{r.name}</h1>
                        <h4>{`${r.dispenserCount} dispensers`}</h4>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <div className="empty">No retailers found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(Home);
