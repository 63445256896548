import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Button,
  Modal,
  Table,
  Space,
  message,
  Input,
  Card,
  Form,
  Avatar,
  Select,
  Divider,
  Upload,
} from "antd";
import { Link, useParams } from "react-router-dom";
import Icon, {
  CaretUpOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  LeftOutlined,
  PlusOutlined,
  MinusOutlined,
  FileImageOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";
import { HistoryTable } from "../../components";
import ImgLeaf from "../../assets/leaf.png";
import ImgSushi from "../../assets/sushi.png";
// import { pick } from "lodash";
const { Option } = Select;
const { Dragger } = Upload;

const ProductDesign = () => {
  const [loading, setLoading] = useState(false);
  const [showRefills, setShowRefills] = useState(false);
  const [colorBg, setColorBg] = useState("#000");
  const [colorText, setColorText] = useState("#fff");
  const [blocks, setBlocks] = useState([]);
  const [fileList, setFileList] = useState([]);
  const { id } = useParams();
  const [themes, setThemes] = useState([
    {
      name: "Default",
      bg: "#000",
      text: "#fff",
      blocks: [
        {
          id: "Image",
          open: false,
        },
      ],
    },
  ]);
  const [addThis, setAddThis] = useState(null);
  const [query, setQuery] = useState("");
  const { form } = Form.useForm();

  const pagePath = [
    {
      title: "Products",
      link: "/products",
    },
    {
      title: "Design",
    },
  ];

  // const filtered =
  //   query.trim() === ""
  //     ? dataSource
  //     : dataSource.filter((d) =>
  //         d.name.toLowerCase().includes(query.toLowerCase())
  //       );

  const toggleRefills = () => {
    setShowRefills(!showRefills);
  };

  const handleBgColorPick = (e) => {
    setColorBg(e.target.value);
  };

  const handleTextColorPick = (e) => {
    setColorText(e.target.value);
  };

  const handleChange = (values) => {
    // console.log(values);
    if (values.bg) {
      setColorBg(values.bg);
    }
    if (values.text) {
      setColorText(values.text);
    }
  };

  const addBlock = () => {
    if (!addThis) {
      return;
    }
    // images.push(null);
    setBlocks([...blocks, { id: addThis, open: false }]);
    setAddThis(null);
  };

  const handleSelect = (e) => {
    setAddThis(e);
  };

  const blockUp = (index) => {
    const temp = [...blocks];
    const a = temp[index - 1];
    temp[index - 1] = temp[index];
    temp[index] = a;
    setBlocks(temp);
  };

  const blockDown = (index) => {
    const temp = [...blocks];
    const a = temp[index + 1];
    temp[index + 1] = temp[index];
    temp[index] = a;
    setBlocks(temp);
  };

  const blockDelete = (index) => {
    console.log(index, blocks[index]);
    // if (blocks[index].id === "Image") {
    //   const temp = [...images];
    //   temp[blocks[index].pos] = null;
    //   setImages(temp);
    // }
    const temp = [...blocks];
    temp.splice(index, 1);
    setBlocks(temp);
    return;
  };

  // const data = [
  //   // {
  //   //   id: "Image",
  //   // },
  //   // {
  //   //   id: "Refill",
  //   // },
  //   // {
  //   //   id: "Ingredients",
  //   // },
  //   // {
  //   //   id: "Nutritional",
  //   // },
  //   // {
  //   //   id: "Branches",
  //   // },
  //   // {
  //   //   id: "RefillHistory",
  //   // },
  //   // {
  //   //   id: "Impact",
  //   // },
  // ];

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const Header = () => {
    return (
      <div
        style={{
          // border: "solid red 2px",
          backgroundColor: "#2a292c",
          display: "flex",
          alignItems: "center",
          paddingTop: 8,
          paddingBottom: 12,
          paddingLeft: 12,
          paddingRight: 12,
          color: "#fffe",
        }}
      >
        <div
          style={{
            paddingLeft: 0,
            // border: "solid red 2px",
          }}
        >
          <LeftOutlined />
        </div>
        <div
          style={{
            flex: 1,
            // border: "solid red 2px",
            textAlign: "center",
          }}
        >
          <span style={{ fontWeight: "600" }}>Product Name</span>
        </div>
        <div
          style={{
            // border: "solid red 2px",
            textAlign: "right",
            paddingRight: 0,
          }}
        >
          <Avatar size={20} />
        </div>
      </div>
    );
  };

  const toggleBlock = (index) => {
    const temp = [...blocks];
    const a = temp[index - 1];
    temp[index].open = !temp[index]?.open || false;
    setBlocks(temp);
  };

  const saveBlocks = () => {
    console.log(
      "blocks",
      blocks,
      blocks.map((b) => ({
        id: b.id,
        value: b.value,
      }))
    );
    return;
    api
      .put("retailertheme", data)
      .then(() => {
        message.success("Retailer created successfully");
        navigate("/retailers");
      })
      .catch(() => {
        message.error("Retailer could not be created");
      });
  };

  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   console.log("FILE", file);

  //   setPreviewImage(file.url || file.preview);
  //   setPreviewOpen(true);
  //   setPreviewTitle(
  //     file.name || file.url?.substring(file.url?.lastIndexOf("/") + 1)
  //   );
  // };

  const props = (pos) => ({
    height: 320,
    style: {
      backgroundColor: "#aaa3",
      color: "#fff",
      borderWidth: 0,
      backgroundImage: `url(${blocks[pos].value})`,
      backgroundSize: "cover",
    },
    listType: "picture",
    // onPreview: handlePreview,
    name: "file",
    multiple: false,
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange: async (info) => {
      // console.log("change", info, info.file?.status || "-", fileList);
      if (!info.file.url && !info.file.preview) {
        const previewImg = await getBase64(info.fileList[0].originFileObj);
        // console.log(previewImg);
        const temp = [...blocks];
        temp[pos].value = previewImg;
        // setPreviewImage(previewImg);
        setBlocks(temp);
      }
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log("uploading", info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
  });

  // console.log(previewImage);

  const Block = ({ id, pos }) => {
    switch (id) {
      case "Image":
        return (
          <Dragger {...props(pos)}>
            {!blocks[pos].value && (
              <>
                <p className="ant-upload-drag-icon">
                  <FileImageOutlined style={{ fontSize: 40 }} />
                </p>
                <p className="ant-upload-hint" style={{ color: "#2d6597" }}>
                  Select Image
                </p>
              </>
            )}
          </Dragger>
        );
        // return <img src={ImgSushi} style={{ width: "100%", height: "100%" }} />;
        break;
      case "Refill":
        return (
          <div style={{ padding: "16px 12px 8px" }}>
            <Space direction="horizontal" align="center">
              <span style={{ fontWeight: "600" }}>Refill:</span>
              <span>15 Dec 2022</span>
            </Space>
          </div>
        );
        break;
      case "Ingredients":
        return (
          <div style={{ padding: "16px 12px 16px" }}>
            <div style={{ fontWeight: "600" }}>Ingredients</div>
            <div>Rice, Seaweed, Salmon, Cucumber.</div>
          </div>
        );
        break;
      case "Nutritional":
        return (
          <div style={{ padding: "16px 12px 16px" }}>
            <div style={{ fontWeight: "600" }}>
              Nutritional Overview (1 roll)
            </div>
            <div>
              <div>350 calories</div>
              <div>8g protein</div>
            </div>
          </div>
        );
        break;
      case "Branches":
        return (
          <div style={{ padding: "16px 12px 16px" }}>
            <div style={{ fontWeight: "600", marginBottom: 8 }}>Branches</div>
            <div
              style={{
                height: 292,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "solid #ccc 1px",
              }}
            >
              <h4 style={{ color: "#bbb" }}>Map</h4>
            </div>
          </div>
        );
        break;
      case "RefillHistory":
        return (
          <div style={{ padding: "16px 12px 8px" }}>
            <div
              onClick={toggleRefills}
              style={{ fontWeight: "600", marginBottom: 6 }}
            >
              {showRefills ? (
                <CaretDownOutlined style={{ marginRight: 6 }} />
              ) : (
                <CaretRightOutlined style={{ marginRight: 6 }} />
              )}
              Refill History
            </div>
            {!!showRefills && (
              <div
                style={{
                  height: 202,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "solid #ccc 1px",
                }}
              >
                <h4 style={{ color: "#bbb" }}>Table</h4>
              </div>
            )}
          </div>
        );
        break;
      case "Impact":
        return (
          <div style={{ padding: "8px 12px" }}>
            {/* <div style={{ fontWeight: "600", marginBottom: 6 }}>Impact</div> */}
            <div
              style={{
                // border: "solid red 2px",
                display: "flex",
                alignItems: "center",
                paddingTop: 8,
                paddingBottom: 12,
                paddingLeft: 12,
                paddingRight: 12,
                // backgroundColor: "#000",
                // color: "#fffe",
              }}
            >
              <div
                style={{
                  paddingLeft: 0,
                  // border: "solid red 2px",
                }}
              >
                <img src={ImgLeaf} style={{ width: 56 }} />
              </div>
              <div
                style={{
                  // border: "solid red 2px",
                  textAlign: "center",
                  paddingRight: 0,
                  paddingLeft: 8,
                  flex: 1,
                }}
              >
                <p style={{ fontSize: 24, marginBottom: 4 }}>758 Kg</p>
                <p>
                  Amount of plastic waste that this container has diverted from
                  landfill
                </p>
              </div>
            </div>
            <div
              style={{
                // border: "solid red 2px",
                display: "flex",
                alignItems: "center",
                paddingTop: 8,
                paddingBottom: 12,
                paddingLeft: 12,
                paddingRight: 12,
                // backgroundColor: "#000",
                // color: "#fffe",
              }}
            >
              <div
                style={{
                  paddingLeft: 0,
                  // border: "solid red 2px",
                }}
              >
                <img src={ImgLeaf} style={{ width: 56 }} />
              </div>
              <div
                style={{
                  // border: "solid red 2px",
                  textAlign: "center",
                  paddingRight: 0,
                  paddingLeft: 8,
                  flex: 1,
                }}
              >
                <p style={{ fontSize: 24, marginBottom: 4 }}>4,050</p>
                <p>Remaining points with Graina</p>
              </div>
            </div>
          </div>
        );
        break;

      default:
        return <div>{id}</div>;
        break;
    }
  };

  // console.log(theme);

  // useEffect(() => {
  //   if (id) {
  //     setLoading(true);
  //     api
  //       .get("retailers")
  //       .then((res) => {
  //         console.log(res);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         console.error("err", err);
  //         setLoading(false);
  //       });
  //   }
  // }, [id]);

  return (
    <div id="retailerDesign">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Space direction="horizontal">
            <Button onClick={() => {}}>Import</Button>
            <Link to="/products">
              <Button>Cancel</Button>
            </Link>
            <Button onClick={saveBlocks} type="primary">
              Save
            </Button>
          </Space>
        </Col>
      </Row>
      <div className="body">
        <Row>
          <div
            style={{
              minWidth: "324px",
              flex: 1,
              // alignSelf: "center",
              // border: "solid #ccc 1px",
              boxShadow: "2px 2px 5px 0px #0001",
              // backgroundColor: "#000",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              style={{
                padding: 0,
                backgroundColor: colorBg,
                color: colorText,
                width: "324px",
                minWidth: "324px",
                minHeight: 640,
                paddingBottom: 32,
                borderRadius: 8,
                overflow: "hidden",
              }}
              bodyStyle={{ padding: 0 }}
            >
              <Header />

              {blocks?.map((d, dI) => (
                <Block id={d.id} key={dI} pos={dI} />
              ))}
            </Card>
          </div>
          <div className="controls">
            <Form
              form={form}
              initialValues={{ bg: "#000000", text: "#ffffff" }}
              onValuesChange={handleChange}
              layout="vertical"
              className="formControls"
            >
              <div className="control">
                <Form.Item name="bg" label="Background Color">
                  <Input type="color" />
                </Form.Item>
              </div>
              <div className="control">
                <Form.Item name="text" label="Text Color">
                  <Input type="color" />
                </Form.Item>
              </div>
              <div
                className="control"
                style={{ marginTop: 32, marginBottom: 24 }}
              >
                <h3>Blocks</h3>
                <div className="blocks">
                  {blocks.map((b, bI) => (
                    <div className="block" key={bI}>
                      <div className="blockHeader">
                        <div className="blockCol">
                          <Button
                            onClick={() => {
                              toggleBlock(bI);
                            }}
                            type="link"
                            style={{ color: "#777" }}
                            icon={b.open ? <MinusOutlined /> : <PlusOutlined />}
                            disabled={bI === 0}
                          />
                        </div>
                        <div
                          onClick={() => {
                            toggleBlock(bI);
                          }}
                          className="blockCol2"
                        >
                          <span>{b.id}</span>
                        </div>
                        <div className="blockCol">
                          <Button
                            onClick={() => {
                              blockUp(bI);
                            }}
                            type="link"
                            style={{ color: bI === 0 ? "#ddd" : "#aaa" }}
                            icon={<CaretUpOutlined />}
                            disabled={bI === 0}
                          />
                        </div>
                        <div className="blockCol">
                          <Button
                            onClick={() => {
                              blockDown(bI);
                            }}
                            type="link"
                            style={{
                              color: bI >= blocks.length - 1 ? "#ddd" : "#aaa",
                            }}
                            disabled={bI >= blocks.length - 1}
                            icon={<CaretDownOutlined />}
                          />
                        </div>
                        <div className="blockCol">
                          <Button
                            onClick={() => {
                              blockDelete(bI);
                            }}
                            type="link"
                            style={{
                              color: bI >= blocks.length - 1 ? "#ddd" : "#aaa",
                            }}
                            icon={
                              <MinusCircleOutlined style={{ color: "#c88" }} />
                            }
                          />
                        </div>
                      </div>
                      {!!b.open && <div className="blockInner">Options</div>}
                    </div>
                  ))}
                </div>
                {/* <Divider style={{ borderColor: "#aaa", borderWidth: 0.5 }} /> */}
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    padding: "19px 16px 0px",
                  }}
                >
                  <Select
                    placeholder="Select block.."
                    style={{ flex: 1, marginRight: 20 }}
                    value={addThis}
                    onChange={handleSelect}
                  >
                    <Option value="Image">Image</Option>
                    <Option value="Refill">Refill</Option>
                    <Option value="Ingredients">Ingredients</Option>
                    <Option value="Nutritional">Nutritional</Option>
                    <Option value="Branches">Branches</Option>
                    <Option value="RefillHistory">RefillHistory</Option>
                    <Option value="Impact">Impact</Option>
                  </Select>
                  <Button type="secondary" onClick={addBlock}>
                    Add
                  </Button>
                </div>
              </div>
              {/* <div className="control">
                <div className="title">Background Color</div>
                <Input type="color" value={colorBg} onChange={handlePick} />
              </div> */}
            </Form>
          </div>
        </Row>
      </div>

      {/* {deleteThis && (
        <Modal
          visible={!!deleteThis}
          onOk={handleDelete}
          onCancel={handleCancel}
        >
          Are you sure you want to delete {deleteThis?.name || "this retailer"}?
        </Modal>
      )} */}
    </div>
  );
};

export default React.memo(ProductDesign);
