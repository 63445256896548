import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  message,
  Select,
  Divider,
  Space,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";
import { UserOutlined, CheckOutlined } from "@ant-design/icons";
const { Option, OptGroup } = Select;

const NewUser = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [dbImages, setDbImages] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const pagePath = [
    {
      title: "Users",
      link: "/users",
    },
    {
      title: id ? "Edit User" : "Add New User",
    },
  ];

  const onChange = () => {};

  const onFinishFailed = ({ value, errorFields, outOfDate }) => {
    console.log(value, errorFields, outOfDate);
    errorFields.map((e) => {
      e.errors.map((eI) => {
        message.error(eI);
      });
    });
  };

  const onFinish = (values) => {
    if (id) {
      handleUpdate(values);
    } else {
      handleCreate(values);
    }
  };

  const handleCreate = (data) => {
    api
      .post("user", data)
      .then(() => {
        message.success("User created successfully");
        navigate("/users");
      })
      .catch((e) => {
        if (e.response?.data?.error?.code === 11000) {
          message.error("Email address already in use");
        } else {
          message.error("User could not be created");
        }
      });
  };

  const handleUpdate = (data) => {
    api
      .put(`user/${id}`, data)
      .then(() => {
        message.success("Users updated successfully");
        navigate("/users");
      })
      .catch((e) => {
        if (e.response?.data?.error?.code === 11000) {
          message.error("Email address already in use");
        } else {
          message.error("User could not be updated");
        }
      });
  };

  useEffect(() => {
    if (id) {
      api
        .get(`user/${id}`)
        .then((res) => {
          console.log(res);
          if (res.data?.data) {
            form.setFieldsValue({
              name: res.data.data.name,
              username: res.data.data.username,
              email: res.data.data.email,
              role: res.data.data.role,
              status: res.data.data.status,
            });
            if (res.data.data?.images?.length) {
              setDbImages(
                res.data.data.images.map((ig) => ({ path: ig, delete: false }))
              );
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [id]);

  return (
    <div id="users">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/users">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="user-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
            status: true,
          }}
          onValuesChange={onChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Card style={{ marginBottom: 16 }}>
            <Divider orientation="left" orientationMargin="0">
              <Space align="center">
                <UserOutlined style={{ color: "#939397", fontSize: 18 }} />
                <h3>User Details</h3>
              </Space>
            </Divider>
            <div className="form-row">
              <Form.Item
                label="Full Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Full name is required",
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label="User Name"
                name="username"
                rules={[
                  {
                    type: "username",
                    required: true,
                    message: "User Name is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Role" name="role">
                <Select placeholder="Select one">
                  <Option value="1">Admin</Option>
                  <Option value="2">Operator</Option>
                  <Option value="3">User</Option>
                  <Option value="4">Vendor</Option>
                </Select>
              </Form.Item>
            </div>
          </Card>
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Divider orientation="left" orientationMargin="0">
                <Space align="center">
                  <CheckOutlined style={{ color: "#939397", fontSize: 18 }} />
                  <h3>Account status</h3>
                </Space>
              </Divider>
              <Form.Item
                name="status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Select placeholder="Select one">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {id ? "Update" : "Add"} User
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(NewUser);
