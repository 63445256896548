import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Button, Modal, Table, Space, message, Input } from "antd";
import { Link } from "react-router-dom";
import Icon, {
  EditOutlined,
  DeleteOutlined,
  CloseSquareOutlined,
  PlusSquareOutlined,
  SearchOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";
import { HistoryTable } from "../../components";
import ProductDetail from "./ProductDetail";

const ThemeSvg = () => (
  <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none">
    <path
      d="M20.5096 9.54C20.4243 9.77932 20.2918 9.99909 20.12 10.1863C19.9483 10.3735 19.7407 10.5244 19.5096 10.63C18.2796 11.1806 17.2346 12.0745 16.5002 13.2045C15.7659 14.3345 15.3733 15.6524 15.3696 17C15.3711 17.4701 15.418 17.9389 15.5096 18.4C15.5707 18.6818 15.5747 18.973 15.5215 19.2564C15.4682 19.5397 15.3588 19.8096 15.1996 20.05C15.0649 20.2604 14.8877 20.4403 14.6793 20.5781C14.4709 20.7158 14.2359 20.8085 13.9896 20.85C13.4554 20.9504 12.9131 21.0006 12.3696 21C11.1638 21.0006 9.97011 20.7588 8.85952 20.2891C7.74893 19.8194 6.74405 19.1314 5.90455 18.2657C5.06506 17.4001 4.40807 16.3747 3.97261 15.2502C3.53714 14.1257 3.33208 12.9252 3.36959 11.72C3.4472 9.47279 4.3586 7.33495 5.92622 5.72296C7.49385 4.11097 9.60542 3.14028 11.8496 3H12.3596C14.0353 3.00042 15.6777 3.46869 17.1017 4.35207C18.5257 5.23544 19.6748 6.49885 20.4196 8C20.6488 8.47498 20.6812 9.02129 20.5096 9.52V9.54Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M8 16.01L8.01 15.9989"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 12.01L6.01 11.9989"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 8.01L8.01 7.99889"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 6.01L12.01 5.99889"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 8.01L16.01 7.99889"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ThemeIcon = (props) => <Icon component={ThemeSvg} {...props} />;

const Products = () => {
  const [dataSource, setDataSource] = useState([]);
  const [deleteThis, setDeleteThis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [query, setQuery] = useState("");

  const pagePath = [
    {
      title: `Products (${dataSource.length})`,
    },
  ];

  const handleDelete = () => {
    setDeleting(true);
    api
      .delete(`product/${deleteThis._id}`)
      .then(() => {
        setDataSource(dataSource.filter((s) => s._id !== deleteThis._id));
        message.success(
          `${deleteThis?.name || "Product"} deleted successfully`
        );
        setDeleteThis(null);
        setDeleting(false);
      })
      .catch(() => {
        message.error(`${deleteThis?.name || "Product"} could not be deleted`);
        setDeleting(false);
      });
  };
  const handleCancel = () => {
    setDeleteThis(null);
  };

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: "Name",
      key: "packagingStill",
      render: (row) => (
        <Space direction="horizontal" size="middle">
          {row?.packagingStill ? (
            <img
              // shape="square"
              src={`https://api.zerotag.au/static/products/${row?.packagingStill}`}
              // size={48}
              style={{ height: 36 }}
            />
          ) : (
            <PictureOutlined
              className="iconPlaceholder"
              style={{ fontSize: 36 }}
            />
          )}
          <span>{row.name}</span>
        </Space>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (cell) => cell?.name || "-",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (cell) => cell?.name || "-",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (cell) => (cell ? "Active" : "Inactive"),
    // },
    // {
    //   title: "No. of Subcategories",
    //   dataIndex: "subcategories",
    //   render: (cell) => cell?.length || 0,
    // },
    {
      title: "Actions",
      width: 110,
      render: (row) => (
        <Space>
          {/* <Link to={`/product/design/${row._id}`}>
            <Button
              type="text"
              disabled={loading || deleting}
              title="Design"
              icon={<ThemeIcon style={{ color: "#649575" }} />}
            />
          </Link> */}
          <Link to={`/product/${row._id}`}>
            <Button
              type="text"
              disabled={loading || deleting}
              icon={<EditOutlined style={{ color: "#66f" }} />}
            />
          </Link>
          <Button
            type="text"
            disabled={loading || deleting}
            onClick={() => {
              setDeleteThis(row);
            }}
            icon={<DeleteOutlined style={{ color: "#d66" }} />}
          />
        </Space>
      ),
    },
  ];
  useEffect(() => {
    setLoading(true);
    api
      .get("products")
      .then((res) => {
        setDataSource(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err", err);
        setLoading(false);
      });
  }, []);
  const filtered =
    query.trim() === ""
      ? dataSource
      : dataSource.filter((d) =>
          d.name.toLowerCase().includes(query.toLowerCase())
        );
  return (
    <div id="products">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/product/new">
            <Button type="primary">Add New</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Input
          autoFocus
          style={{ marginBottom: 12 }}
          placeholder="Search.."
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
        <Table
          dataSource={filtered}
          columns={columns}
          rowKey="_id"
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <CloseSquareOutlined
                  onClick={(e) => {
                    onExpand(record, e);
                  }}
                  style={{ color: "#e94b4c" }}
                />
              ) : (
                <PlusSquareOutlined
                  onClick={(e) => {
                    onExpand(record, e);
                  }}
                  style={{ color: "#7d7d7d" }}
                />
              ),
            expandedRowRender: (record) => {
              return <ProductDetail id={record._id} />;
              // <HistoryTable dataSource={record.logs} />;
            },
          }}
        />
      </div>
      {deleteThis && (
        <Modal
          visible={!!deleteThis}
          onOk={handleDelete}
          onCancel={handleCancel}
        >
          Are you sure you want to delete {deleteThis?.name || "this product"}?
        </Modal>
      )}
    </div>
  );
};

export default React.memo(Products);
