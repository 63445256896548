import { Button, Form, Input, Select, Spin, Tabs, message } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { HistoryTable, Loader, RefillsTable } from "../../components";
import { api } from "../../lib";
// import QREmbed from "./QREmbed";
const { TabPane } = Tabs;
const { Option } = Select;
const ProductDetail = ({ id }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState(null);
  // console.log(id);
  useEffect(() => {
    if (id) {
      setLoading(true);
      api
        .get(`product/${id}`)
        .then((res) => {
          // console.log("RES", res);
          if (res.data?.data) {
            setRecord(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="detailWrap">
      <HistoryTable dataSource={record?.logs || []} />
      {/* <Tabs defaultActiveKey="history" centered>
        <TabPane tab="History" key="history">
          <HistoryTable dataSource={record?.logs || []} />
        </TabPane>
        <TabPane tab="Code" key="code">
          <QREmbed value={record?.code || null} />
        </TabPane>
      </Tabs> */}
    </div>
  );
};

export default ProductDetail;
