import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Tabs,
  Input,
  Select,
  Modal,
  Avatar,
  Space,
  DatePicker,
  Divider,
  message,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../lib";
import { Breadcrumbs, Loader } from "../../components";
import moment from "moment";
const { Option, OptGroup } = Select;
const { Meta } = Card;

const RestockForm = ({ id, name, closeModal, fetchData }) => {
  // const { user, isLoggedIn } = store.useState((state) => state);
  const [brands, setBrands] = useState([]);
  const [product, setProduct] = useState(null);
  const [refilledOn, setRefilledOn] = useState(moment());
  const [bestBefore, setBestBefore] = useState(moment());
  const [expiresOn, setExpiresOn] = useState(moment());
  const [loading, setLoading] = useState(false);
  console.log("ID", id, name);

  useEffect(() => {
    setLoading(true);
    api
      .get(`constants/brandproducts`)
      .then((res) => {
        console.log(res.data.data);
        setBrands(res.data?.data || []);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err", err);
        setLoading(false);
      });
  }, []);

  const onProductChange = (value) => {
    setProduct(value);
  };

  const onRefilledChange = (date) => {
    setRefilledOn(date);
  };

  const onBestBeforeChange = (date) => {
    setBestBefore(date);
  };

  const onExpiryChange = (date) => {
    setExpiresOn(date);
  };

  const doRestock = () => {
    if (!!product && !!refilledOn && !!expiresOn) {
      const values = {
        product: product,
        dispenser: id,
        refilledOn: refilledOn,
        bestBefore: bestBefore,
        expiresOn: expiresOn,
      };
      api
        .post("dashboard/restock", values)
        .then(() => {
          message.success("Dispenser restocked successfully");
          closeModal();
          fetchData();
        })
        .catch(() => {
          message.error("Dispenser could not be restocked");
        });
    } else {
      message.error("Please fill all required fields");
    }
  };

  return (
    <div id="restock">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <p>
                <span className="required">*</span>Product
              </p>
            </Col>
            <Col span={12}>
              <Select
                onChange={onProductChange}
                value={product}
                allowClear
                style={{ width: "100%" }}
              >
                {brands.map((brand) => (
                  <OptGroup label={brand.name} key={brand._id}>
                    {brand.products.map((bp) => (
                      <Option value={bp._id} key={bp._id}>
                        {bp.name}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p>
                <span className="required">*</span>Refilled On
              </p>
            </Col>
            <Col span={12}>
              <DatePicker
                format={"YYYY-MM-DD"}
                value={refilledOn}
                style={{ width: "100%" }}
                onChange={onRefilledChange}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p>Best Before</p>
            </Col>
            <Col span={12}>
              <DatePicker
                format={"YYYY-MM-DD"}
                value={bestBefore}
                style={{ width: "100%" }}
                onChange={onBestBeforeChange}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p>
                <span className="required">*</span>Expires On
              </p>
            </Col>
            <Col span={12}>
              <DatePicker
                format={"YYYY-MM-DD"}
                value={expiresOn}
                style={{ width: "100%" }}
                onChange={onExpiryChange}
              />
            </Col>
          </Row>
          <Divider />
          <div className="footer">
            <Row>
              <Col flex={1}></Col>
              <Col>
                <Button onClick={closeModal} style={{ marginRight: 12 }}>
                  Cancel
                </Button>
                <Button onClick={doRestock} type="primary">
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(RestockForm);
