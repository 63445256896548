import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Tabs,
  Input,
  Select,
  Modal,
  Avatar,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../lib";
import { Breadcrumbs, Loader } from "../../components";
const { Option } = Select;
const { Meta } = Card;

const RetailerView = () => {
  // const { user, isLoggedIn } = store.useState((state) => state);
  const [retailer, setRetailer] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [pagePath, setPagePath] = useState([
    {
      title: `Dashboard`,
      link: "/",
    },
    {
      title: "Retailer",
    },
  ]);
  const { id } = params;

  console.log(id);

  useEffect(() => {
    setLoading(true);
    api
      .get(`dashboard/retailer/${id}`)
      .then((res) => {
        console.log(res.data.data);
        setRetailer(res.data.data);
        setPagePath((pp) => [
          pp[0],
          {
            title: res.data.data.name,
          },
        ]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err", err);
        setLoading(false);
      });
  }, []);

  return (
    <div id="home">
      {/* <h1>{`Welcome back${isLoggedIn ? ", " : ""}${user?.name}`}</h1> */}
      <Breadcrumbs pagePath={pagePath} />
      {loading ? (
        <Loader />
      ) : (
        <div>
          {retailer?.dispensers?.length ? (
            <Row gutter={[16, 16]} style={{ marginTop: 32 }}>
              {retailer?.dispensers?.map((r, rI) => (
                <Col span={12} key={rI}>
                  <Card
                    hoverable={true}
                    onClick={() => {
                      console.log(r._id);
                      navigate(`/view/dispenser/${r._id}`);
                    }}
                  >
                    <Row>
                      <Col span={8}>
                        {r.image ? (
                          <img
                            style={{ width: "80%" }}
                            src={`https://api.zerotag.au/static/dispensers/${r.image}`}
                          />
                        ) : (
                          <div className="avatar-placeholder" />
                        )}
                      </Col>
                      <Col span={16}>
                        <h1 style={{ marginBottom: 5 }}>{r.name}</h1>
                        {r.product ? (
                          <>
                            <h4 style={{ marginBottom: 1 }}>
                              {r.product.name}
                            </h4>
                            <h5>{r.product.brand?.name || "-"}</h5>
                          </>
                        ) : (
                          <h4>Empty</h4>
                        )}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <div className="empty">No dispensers found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(RetailerView);
