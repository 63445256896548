import React, { useCallback, useEffect, useRef, useState } from "react";
import "./design.scss";
import { Row, Col, Button, message, Space } from "antd";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";

var handleRadius = 10;
var dragTL = false;
var dragBL = false;
var dragTR = false;
var dragBR = false;
var dragWholeRect = false;
var mouseX, mouseY;
var startX, startY;
const rect = {
  left: 175,
  top: 175,
  width: 250,
  height: 250,
};

const AdjustDesign = () => {
  const canvasRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [datasource, setDatasource] = useState(null);
  const { id } = useParams();

  const pagePath = [
    {
      title: "Designs",
      link: "/designs",
    },
    {
      title: "Adjust",
    },
  ];

  async function drawCircle(x, y, radius) {
    const canvas = canvasRef.current;
    var ctx = await canvas.getContext("2d");
    ctx.fillStyle = "#c757e7";
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.fill();
  }

  function drawHandles() {
    drawCircle(rect.left, rect.top, handleRadius);
    drawCircle(rect.left + rect.width, rect.top, handleRadius);
    drawCircle(rect.left + rect.width, rect.top + rect.height, handleRadius);
    drawCircle(rect.left, rect.top + rect.height, handleRadius);
  }

  function drawRectInCanvas() {
    const canvas = canvasRef.current;
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.lineWidth = "6";
    ctx.fillStyle = "rgba(199, 87, 231, 0.2)";
    ctx.strokeStyle = "#c757e7";
    ctx.rect(rect.left, rect.top, rect.width, rect.height);
    ctx.fill();
    ctx.stroke();
    drawHandles();
  }

  function mouseUp() {
    // if (editing === false) {
    //   return;
    // }
    dragTL = dragTR = dragBL = dragBR = false;
    dragWholeRect = false;
  }

  function checkInRect(x, y, r) {
    return (
      x > r.left && x < r.width + r.left && y > r.top && y < r.top + r.height
    );
  }

  function checkCloseEnough(p1, p2) {
    return Math.abs(p1 - p2) < handleRadius;
  }

  function getMousePos(canvas, evt) {
    var clx, cly;
    if (evt.type === "touchstart" || evt.type === "touchmove") {
      clx = evt.touches[0].clientX;
      cly = evt.touches[0].clientY;
    } else {
      clx = evt.clientX;
      cly = evt.clientY;
    }
    var boundingRect = canvas.getBoundingClientRect();
    return {
      x: clx - boundingRect.left,
      y: cly - boundingRect.top,
    };
  }

  function mouseDown(e) {
    var pos = getMousePos(this, e);
    mouseX = pos.x;
    mouseY = pos.y;
    // 0. inside movable rectangle
    if (checkInRect(mouseX, mouseY, rect)) {
      dragWholeRect = true;
      startX = mouseX;
      startY = mouseY;
    }
    // 1. top left
    else if (
      checkCloseEnough(mouseX, rect.left) &&
      checkCloseEnough(mouseY, rect.top)
    ) {
      dragTL = true;
    }
    // 2. top right
    else if (
      checkCloseEnough(mouseX, rect.left + rect.width) &&
      checkCloseEnough(mouseY, rect.top)
    ) {
      dragTR = true;
    }
    // 3. bottom left
    else if (
      checkCloseEnough(mouseX, rect.left) &&
      checkCloseEnough(mouseY, rect.top + rect.height)
    ) {
      dragBL = true;
    }
    // 4. bottom right
    else if (
      checkCloseEnough(mouseX, rect.left + rect.width) &&
      checkCloseEnough(mouseY, rect.top + rect.height)
    ) {
      dragBR = true;
    }
    // (5.) none of them
    else {
      // handle not resizing
    }
    drawRectInCanvas();
  }

  function mouseMove(e) {
    const canvas = canvasRef.current;
    var pos = getMousePos(this, e);
    mouseX = pos.x;
    mouseY = pos.y;
    if (dragWholeRect) {
      e.preventDefault();
      e.stopPropagation();
      const dx = mouseX - startX;
      const dy = mouseY - startY;
      if (rect.left + dx > 0 && rect.left + dx + rect.width < canvas.width) {
        rect.left += dx;
      }
      if (rect.top + dy > 0 && rect.top + dy + rect.height < canvas.height) {
        rect.top += dy;
      }
      startX = mouseX;
      startY = mouseY;
    } else if (dragTL) {
      e.preventDefault();
      e.stopPropagation();
      const newSide =
        (Math.abs(rect.left + rect.width - mouseX) +
          Math.abs(rect.height + rect.top - mouseY)) /
        2;
      if (newSide > 150) {
        rect.left = rect.left + rect.width - newSide;
        rect.top = rect.height + rect.top - newSide;
        rect.width = rect.height = newSide;
      }
    } else if (dragTR) {
      e.preventDefault();
      e.stopPropagation();
      const newSide =
        (Math.abs(mouseX - rect.left) +
          Math.abs(rect.height + rect.top - mouseY)) /
        2;
      if (newSide > 150) {
        rect.top = rect.height + rect.top - newSide;
        rect.width = rect.height = newSide;
      }
    } else if (dragBL) {
      e.preventDefault();
      e.stopPropagation();
      const newSide =
        (Math.abs(rect.left + rect.width - mouseX) +
          Math.abs(rect.top - mouseY)) /
        2;
      if (newSide > 150) {
        rect.left = rect.left + rect.width - newSide;
        rect.width = rect.height = newSide;
      }
    } else if (dragBR) {
      e.preventDefault();
      e.stopPropagation();
      const newSide =
        (Math.abs(rect.left - mouseX) + Math.abs(rect.top - mouseY)) / 2;
      if (newSide > 150) {
        rect.width = rect.height = newSide;
      }
    }
    drawRectInCanvas();
  }

  const init = async () => {
    // Draw resize square
    // rect.left = 175;
    // rect.top = 175;
    // rect.width = 250;
    // rect.height = 250;
    const canvas = canvasRef.current;
    canvas.width = 600;
    canvas.height = 600;
    const ctx = await canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.lineWidth = "6";
    ctx.fillStyle = "rgba(199, 87, 231, 0.2)";
    ctx.strokeStyle = "#c757e7";
    ctx.rect(rect.left, rect.top, rect.width, rect.height);
    // ctx.rect(rect.left, rect.top, rect.width, rect.height);
    ctx.fill();
    ctx.stroke();
    drawHandles();
    // Register event handlers
    // canvas.addEventListener("mousedown", mouseDown, false);
    // canvas.addEventListener("mouseup", mouseUp, false);
    // canvas.addEventListener("mousemove", mouseMove, false);
    // canvas.addEventListener("touchstart", mouseDown);
    // canvas.addEventListener("touchmove", mouseMove);
    // canvas.addEventListener("touchend", mouseUp);
    // updateHiddenInputs()
  };

  const resetSquare = (data) => {
    if (data.left) {
      rect.left = data.left;
    } else {
      rect.left = 175;
    }
    if (data.top) {
      rect.top = data.top;
    } else {
      rect.top = 175;
    }
    if (data.width) {
      rect.width = data.width;
    } else {
      rect.width = 250;
    }
    if (data.height) {
      rect.height = data.height;
    } else {
      rect.height = 250;
    }
    drawRectInCanvas();
  };

  useEffect(() => {
    if (id) {
      api
        .get(`design/${id}`)
        .then(async (res) => {
          setDatasource(res.data.data);
          resetSquare(res.data.data);
          // drawRectInCanvas();
          init();
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id]);

  const handleSave = () => {
    setSubmitting(true);
    api
      .put(`adjust/${id}`, rect)
      .then(() => {
        setEditing(false);
        message.success("Design updated successfully");
      })
      .catch(() => {
        message.error("Design could not be updated");
        setEditing(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const centerH = () => {
    const canvas = canvasRef.current;
    rect.left = (canvas.width - rect.width) / 2;
    drawRectInCanvas();
  };

  const centerV = () => {
    const canvas = canvasRef.current;
    rect.top = (canvas.height - rect.height) / 2;
    drawRectInCanvas();
  };

  const handleListeners = useCallback(
    (flag) => {
      const canvas = canvasRef.current;
      if (flag) {
        canvas.addEventListener("mousedown", mouseDown, false);
        canvas.addEventListener("mouseup", mouseUp, false);
        canvas.addEventListener("mousemove", mouseMove, false);
        canvas.addEventListener("touchstart", mouseDown);
        canvas.addEventListener("touchmove", mouseMove);
        canvas.addEventListener("touchend", mouseUp);
      } else {
        canvas.removeEventListener("mousedown", mouseDown);
        canvas.removeEventListener("mouseup", mouseUp);
        canvas.removeEventListener("mousemove", mouseMove);
        canvas.removeEventListener("touchstart", mouseDown);
        canvas.removeEventListener("touchmove", mouseMove);
        canvas.removeEventListener("touchend", mouseUp);
      }
    },
    [canvasRef]
  );

  const startEdit = () => {
    setEditing(true);
    // init();
    handleListeners(true);
  };

  const endEdit = () => {
    setEditing(false);
    resetSquare(datasource);
    handleListeners(false);
  };

  return (
    <div id="adjustDesign">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Space direction="horizontal">
            {editing ? (
              <>
                <Button
                  onClick={handleSave}
                  loading={submitting}
                  type="primary"
                >
                  Save
                </Button>
                <Button onClick={endEdit}>Cancel Edit</Button>
              </>
            ) : (
              <Button onClick={startEdit} type="primary">
                Modify
              </Button>
            )}
            <Link to="/designs">
              <Button>Back</Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <div className="body">
        <h4>
          {editing
            ? "Move/resize the square to where you would like the QR code to appear on the design"
            : "QR code will be printed in the highlighted area"}
        </h4>
        <p>
          {editing
            ? "Make sure to place it on the transparent area of the image."
            : "Click on Modify to make changes"}
        </p>
        <br />
        <div className="designArea">
          <canvas ref={canvasRef} className="canvas-container"></canvas>
          <div className="imgBg">
            {datasource?.image && (
              <img
                src={`https://api.zerotag.au/static/designs/${datasource.image}`}
                className="bgImg"
              />
            )}
          </div>
        </div>
        {editing && (
          <Space direction="horizontal" className="controls">
            <Button onClick={centerH}>Center H</Button>
            <Button onClick={centerV}>Center V</Button>
          </Space>
        )}
      </div>
    </div>
  );
};

export default React.memo(AdjustDesign);
