import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  PieChartOutlined,
  FileOutlined,
  PoweroffOutlined,
  SettingOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import "./style.scss";
import { api, store } from "../../lib";

const { SubMenu } = Menu;

const { Content, Sider } = Layout;

const Layout1 = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = store.useState((state) => state);
  console.log(user);
  const onCollapse = (e) => {
    setCollapsed(e);
  };

  return (
    <Layout style={{ height: "100vh", overflow: "hidden" }}>
      {location.pathname !== "/login" && (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <div className="logo">
            {!collapsed && (
              <h1>
                ZeroTag
                <br />
                <small>Admin</small>
              </h1>
            )}
          </div>
          <Menu
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
            onClick={({ key }) => {
              if (key === "signout") {
                document.cookie =
                  "AUTH_TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                api.setToken(null);
                navigate("/login");
              }
            }}
          >
            <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
              <Link to="/">Dashboard</Link>
            </Menu.Item>

            <SubMenu key="sub3" icon={<GlobalOutlined />} title="Masters">
              <Menu.Item key="categories" icon={<FileOutlined />}>
                <Link to="/categories">Categories</Link>
              </Menu.Item>
              <Menu.Item key="brands" icon={<FileOutlined />}>
                <Link to="/brands">Brands</Link>
              </Menu.Item>
              <Menu.Item key="products" icon={<FileOutlined />}>
                <Link to="/products">Products</Link>
              </Menu.Item>
              <Menu.Item key="retailers" icon={<FileOutlined />}>
                <Link to="/retailers">Retailers</Link>
              </Menu.Item>
              <Menu.Item key="branches" icon={<FileOutlined />}>
                <Link to="/branches">Branches</Link>
              </Menu.Item>
              <Menu.Item key="dispensers" icon={<FileOutlined />}>
                <Link to="/dispensers">Dispensers</Link>
              </Menu.Item>
              <Menu.Item key="dispensersets" icon={<FileOutlined />}>
                <Link to="/dispensersets">Dispensersets</Link>
              </Menu.Item>
              <Menu.Item key="users" icon={<FileOutlined />}>
                <Link to="/users">Users</Link>
              </Menu.Item>
              <Menu.Item key="zerotags" icon={<FileOutlined />}>
                <Link to="/zerotags">Zerotags</Link>
              </Menu.Item>
              <Menu.Item key="variants" icon={<FileOutlined />}>
                <Link to="/variants">Variants</Link>
              </Menu.Item>
              <Menu.Item key="refills" icon={<FileOutlined />}>
                <Link to="/refills">Refills</Link>
              </Menu.Item>
              {/* <Menu.Divider /> */}
              {/* <Menu.Item key="designs" icon={<FileOutlined />}>
              <Link to="/designs">QR Design</Link>
            </Menu.Item>
            <Menu.Item key="generate" icon={<FileOutlined />}>
              <Link to="/generate">Generate QR</Link>
            </Menu.Item>
            <Menu.Item key="downloads" icon={<FileOutlined />}>
              <Link to="/downloads">Downloads</Link>
            </Menu.Item> */}
            </SubMenu>
            {user?.role == 1 ? (
              <Menu.Item key="account" icon={<SettingOutlined />}>
                <Link to="/account">Account</Link>
              </Menu.Item>
            ) : null}
            <Menu.Item key="signout" icon={<PoweroffOutlined />}>
              Sign Out
            </Menu.Item>
          </Menu>
        </Sider>
      )}
      <Layout className="site-layout">
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default Layout1;
