import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  message,
  Avatar,
  InputNumber,
  Space,
  Tag,
  Modal,
} from "antd";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Loader } from "../../components";
import { api } from "../../lib";
import { CheckCircleFilled, WarningOutlined } from "@ant-design/icons";

const Generate = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [designs, setDesigns] = useState([]);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [prefix, setPrefix] = useState("");
  const [start, setStart] = useState(1);
  const [digits, setDigits] = useState(3);
  const [qty, setQty] = useState(50);
  const [overlap, setOverlap] = useState(false);
  const [overlapSuppressed, setOverlapSuppressed] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const pagePath = [
    {
      title: "QR Code Generator",
    },
  ];

  useEffect(() => {
    api
      .get(`generate`)
      .then((res) => {
        // console.log(res);
        setDesigns(res.data.data);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onChange = (_, all) => {
    setPrefix(all.prefix);
    setStart(all.start);
    setDigits(all.digits);
    setQty(all.qty);
  };

  const onFinish = (values) => {
    // console.log("sub", values, selectedDesign);
    if (!values.prefix || values.prefix?.trim() === "") {
      message.error("Please add a prefix");
      return;
    }
    const data = {
      design: selectedDesign,
      prefix: values.prefix,
      start: values.start,
      digits: values.digits,
      qty: values.qty,
      overlapSuppressed,
    };
    setSubmitting(true);
    api
      .post("generate", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data?.success) {
          message.success("QR Codes generated successfully");
          navigate("/downloads");
        } else if (res.data?.overlap) {
          setOverlap(true);
        } else {
          message.error("QR Codes could not be generated");
        }
      })
      .catch(() => {
        message.error("QR Codes could not be generated");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const toggleOverlap = () => {
    setOverlap(false);
    setOverlapSuppressed(false);
  };

  const suppressOverlap = () => {
    setOverlapSuppressed(true);
    setOverlap(false);
    form.submit();
  };

  return (
    <div id="generate">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          {/* <Link to="/downloads">
            <Button>Cancel</Button>
          </Link> */}
        </Col>
      </Row>
      <div className="body">
        {loading ? (
          <Loader />
        ) : (
          <Form
            name="generate-form"
            form={form}
            layout="vertical"
            initialValues={{
              prefix: "",
              digits: 3,
              start: 1,
              qty: 50,
            }}
            onValuesChange={onChange}
            onFinish={onFinish}
          >
            <Card
              bodyStyle={{ padding: "0 0 8px" }}
              style={{ marginBottom: 16 }}
            >
              <h4>1. Pick a design</h4>
              <Row gutter={[16, 16]}>
                {designs.map((d, dI) => (
                  <Col key={dI} sm={12} md={8} xl={6}>
                    <Card
                      bodyStyle={{ padding: "0 12px" }}
                      bordered
                      className="dWrap"
                      onClick={() => {
                        setSelectedDesign(d._id);
                      }}
                    >
                      <Avatar
                        src={`https://api.zerotag.au/static/designs/${d.image}`}
                        // size={128}
                        shape="square"
                        className="dImg"
                      />
                      <h5>{d.name}</h5>
                      <CheckCircleFilled
                        className="dCheck"
                        style={{
                          color: selectedDesign === d._id ? "#80c080" : "#0002",
                        }}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
            <Card
              bodyStyle={{ padding: "0 0 12px" }}
              style={{ marginBottom: 16 }}
            >
              <h4>2. Set QR Code Value</h4>
              <Row gutter={16}>
                <Col sm={12} md={8}>
                  <Form.Item
                    label="Prefix"
                    name="prefix"
                    rules={[
                      {
                        pattern: new RegExp(/^[a-zA-Z0-9-_]*$/),
                        message:
                          "Letters, digits, hyphens and underscores only",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col sm={12} md={8}>
                  <Form.Item label="Starting Number" name="start">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      controls={true}
                    />
                  </Form.Item>
                </Col>
                <Col sm={12} md={8}>
                  <Form.Item label="# Significant Digits" name="digits">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={3}
                      controls={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {prefix &&
                prefix.trim() !== "" &&
                start &&
                start?.toString().trim() !== "" &&
                qty &&
                qty?.toString().trim() !== "" && (
                  <Space
                    direction="horizontal"
                    size="large"
                    className="preview"
                  >
                    <p style={{ marginBottom: 0 }}>Preview</p>
                    <Tag color="blue">{`${prefix || ""}${String(start).padStart(
                      digits || 3,
                      0
                    )}`}</Tag>
                    <p style={{ marginBottom: 0 }}>to</p>
                    <Tag color="blue">{`${prefix || ""}${
                      String(start + qty - 1).padStart(digits || 3, 0) || ""
                    }`}</Tag>
                  </Space>
                )}
            </Card>
            <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 16 }}>
              <h4>3. Select Quantity</h4>
              <Row gutter={16}>
                <Col sm={12} md={12}>
                  <Form.Item label="Number of tags to generate" name="qty">
                    <InputNumber
                      style={{ width: "100%" }}
                      min={1}
                      controls={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting}
              disabled={selectedDesign === null}
            >
              Generate
            </Button>
          </Form>
        )}
      </div>
      <Modal
        onCancel={toggleOverlap}
        onOk={suppressOverlap}
        okText="Generate anyway"
        cancelText="Cancel"
        open={overlap}
        okButtonProps={{ type: "secondary" }}
        cancelButtonProps={{ type: "primary" }}
      >
        <h2>Overlap Warning</h2>
        <p style={{ fontWeight: "500" }}>
          <WarningOutlined
            style={{
              color: "red",
              fontSize: 20,
              marginRight: 12,
            }}
          />
          Found existing QR codes within the value range requested.
        </p>
        <p>
          You can suppress this warning and generate the qr codes any way, but
          please note that you will <strong>not</strong> be able to import
          duplicated tags.
        </p>
      </Modal>
    </div>
  );
};

export default React.memo(Generate);
