import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Button,
  Modal,
  Table,
  Space,
  message,
  Input,
  Select,
  Dropdown,
} from "antd";
import { Link } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  CloseSquareOutlined,
  PlusSquareOutlined,
  ImportOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";
// import TagDetails from "./TagDetail";

const Variants = () => {
  const [dataSource, setDataSource] = useState([]);
  const [deleteThis, setDeleteThis] = useState(null);
  const [refillThis, setRefillThis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [prefix, setPrefix] = useState(null);
  const [user, setUser] = useState(null);
  const [query, setQuery] = useState("");

  const pagePath = [
    {
      title: `Variants (${dataSource.length})`,
    },
  ];

  const handleDelete = () => {
    setDeleting(true);
    api
      .delete(`variant/${deleteThis._id}`)
      .then(() => {
        setDataSource(dataSource.filter((s) => s._id !== deleteThis._id));
        message.success(
          `${deleteThis?.name || "Variant"} deleted successfully`
        );
        setDeleteThis(null);
        setDeleting(false);
      })
      .catch(() => {
        message.error(`${deleteThis?.name || "Variant"} could not be deleted`);
        setDeleting(false);
      });
  };

  const handleCancel = () => {
    setDeleteThis(null);
  };

  const columns = [
    // Table.EXPAND_COLUMN,
    {
      title: "",
      render: (row) => (
        <div
          className="preview"
          style={{
            background:
              row.scheme === "SOLID"
                ? row.color1
                : `linear-gradient(${row.color1}, ${row.color2})`,
          }}
        >
          <div className="preview-inner">{/* <p>test</p> */}</div>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    // TODO: Remove & add preview tile
    // {
    //   title: "Scheme",
    //   dataIndex: "scheme",
    //   render: (cell) =>
    //     cell === "LINEAR_GRADIENT" ? "Linear Gradient" : "Solid",
    // },
    {
      title: "Actions",
      width: 110,
      render: (row) => (
        <Space>
          <Link to={`/variant/${row._id}`}>
            <Button
              type="text"
              disabled={loading || deleting}
              icon={<EditOutlined style={{ color: "#66f" }} />}
            />
          </Link>
          <Button
            type="text"
            disabled={loading || deleting}
            onClick={() => {
              setDeleteThis(row);
            }}
            icon={<DeleteOutlined style={{ color: "#d66" }} />}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    api
      .get("variants")
      .then((res) => {
        // console.log(res.data);
        setDataSource(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err2", err);
        setLoading(false);
      });
  }, []);

  return (
    <div id="variants">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Space direction="horizontal">
            <Link to="/variant/new">
              <Button type="primary">Add New</Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <div className="body">
        <div className="header">
          <Input
            autoFocus
            style={{
              // marginLeft: 24,
              alignSelf: "flex-end",
              width: "100%",
              // maxWidth: 400,
            }}
            placeholder="Search.."
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey="_id"
          // expandable={{
          //   expandIcon: ({ expanded, onExpand, record }) =>
          //     expanded ? (
          //       <CloseSquareOutlined
          //         onClick={(e) => {
          //           onExpand(record, e);
          //         }}
          //         style={{ color: "#e94b4c" }}
          //       />
          //     ) : (
          //       <PlusSquareOutlined
          //         onClick={(e) => {
          //           onExpand(record, e);
          //         }}
          //         style={{ color: "#7d7d7d" }}
          //       />
          //     ),
          //   expandedRowRender: (record) => {
          //     return <TagDetails id={record._id} updater={updateTag} />;
          //     // return <HistoryTable dataSource={record.logs} />;
          //   },
          // }}
        />
      </div>
      {deleteThis && (
        <Modal
          visible={!!deleteThis}
          onOk={handleDelete}
          onCancel={handleCancel}
        >
          Are you sure you want to delete {deleteThis?.name || "this variant"}?
        </Modal>
      )}
    </div>
  );
};

export default React.memo(Variants);
