import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Tabs,
  Input,
  Select,
  Modal,
  Avatar,
  Space,
  Image,
  message,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";
import RestockForm from "./RestockForm";
import moment from "moment";
import {
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const { Meta } = Card;
const { confirm } = Modal;

const DispenserView = () => {
  // const { user, isLoggedIn } = store.useState((state) => state);
  const [dispenser, setDispenser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showRestock, setShowRestock] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [pagePath, setPagePath] = useState([
    {
      title: `Dashboard`,
      link: "/",
    },
    {
      title: "Retailer",
    },
    {
      title: "Dispenser",
    },
  ]);
  const { id } = params;

  const fetchData = useCallback(() => {
    setLoading(true);
    api
      .get(`dashboard/dispenser/${id}`)
      .then((res) => {
        console.log(res.data.data);
        setDispenser(res.data.data);
        setPagePath(() => [
          {
            title: `Dashboard`,
            link: "/",
          },
          {
            title: res.data.data?.retailer?.name || "Retailer",
            link: `/view/retailer/${res.data.data?.retailer?._id}`,
          },
          {
            title: res.data.data?.name || "Dispenser",
          },
        ]);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err", err);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const closeModal = () => {
    setShowRestock(false);
  };

  const columns = [
    {
      title: "Restocked On",
      dataIndex: "refilledOn",
      render: (cell) => (cell ? moment(cell).format("DD MMM YYYY") : ""),
    },
    {
      title: "Best Before",
      dataIndex: "bestBefore",
      render: (cell) => (cell ? moment(cell).format("DD MMM YYYY") : ""),
    },
    {
      title: "Expires On",
      dataIndex: "expiresOn",
      render: (cell) => (cell ? moment(cell).format("DD MMM YYYY") : ""),
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (cell) => cell?.name || "",
    },
    {
      title: "Restocked By",
      dataIndex: "user",
      render: (cell) => cell?.name || "",
    },
  ];

  const columnsRefill = [
    {
      title: "Refilled On",
      dataIndex: "refilledOn",
      render: (cell) => (cell ? moment(cell).format("DD MMM YYYY") : ""),
    },
    {
      title: "Best Before",
      dataIndex: "bestBefore",
      render: (cell) => (cell ? moment(cell).format("DD MMM YYYY") : ""),
    },
    {
      title: "Expires On",
      dataIndex: "expiresOn",
      render: (cell) => (cell ? moment(cell).format("DD MMM YYYY") : ""),
    },
    {
      title: "Retailer",
      dataIndex: "retailer",
      render: (cell) => cell?.name || "",
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (cell) => cell?.name || "",
    },
    {
      title: "User",
      dataIndex: "user",
      render: (cell) => cell?.name || "",
    },
  ];

  const confirmEmpty = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    api
      .post("dashboard/empty", {
        id: dispenser._id,
      })
      .then(() => {
        message.success("Dispenser emptied");
        setConfirmLoading(false);
        fetchData();
        setOpen(false);
      })
      .catch((err) => {
        message.error(
          err.response?.data?.message || "Dispenser could not be emptied"
        );
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div id="dispenserView">
      <Breadcrumbs pagePath={pagePath} />
      {!!dispenser && (
        <>
          <div>
            {dispenser.product ? (
              <div style={{ marginTop: 30 }}>
                <Card>
                  <Row align="middle">
                    <Col span={6}>
                      <img
                        style={{ width: "80%", marginRight: 40 }}
                        src={
                          dispenser?.product?.packagingStill
                            ? `https://api.zerotag.au/static/products/${dispenser.product.packagingStill}`
                            : null
                        }
                      />
                    </Col>
                    <Col span={18}>
                      <Row>
                        <Col flex={1}>
                          <h1 style={{ marginBottom: 0 }}>
                            {dispenser?.product?.name}
                          </h1>
                          <h5 style={{ marginBottom: 24 }}>
                            {dispenser?.product?.brand?.name || "-"}
                          </h5>
                        </Col>
                        <Col>
                          <Button onClick={confirmEmpty} danger>
                            Empty
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>
                          <h4 style={{ marginBottom: 0 }}>Refilled on</h4>
                          <p>
                            {dispenser.refilledOn
                              ? moment(dispenser.refilledOn).format(
                                  "DD MMM YYYY"
                                )
                              : "-"}
                          </p>
                        </Col>
                        <Col span={8}>
                          <h4 style={{ marginBottom: 0 }}>Best Before</h4>
                          <p>
                            {dispenser.bestBefore
                              ? moment(dispenser.bestBefore).format(
                                  "DD MMM YYYY"
                                )
                              : "-"}
                          </p>
                        </Col>
                        <Col span={8}>
                          <h4 style={{ marginBottom: 0 }}>Expires on</h4>
                          <p>
                            {dispenser.expiresOn
                              ? moment(dispenser.expiresOn).format(
                                  "DD MMM YYYY"
                                )
                              : "-"}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
            ) : (
              <Card
                bordered={true}
                style={{ marginTop: 30, backgroundColor: "#fff6" }}
              >
                <div className="empty">This dispenser is currently empty</div>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowRestock(true);
                    }}
                  >
                    Restock
                  </Button>
                </Space>
              </Card>
            )}
          </div>
          <div>
            {dispenser.refills && (
              <div className="refill-log">
                <Row>
                  <Col flex={1}>
                    <h3 style={{ marginBottom: 12, display: "block" }}>
                      Refills
                    </h3>
                  </Col>
                  <Col>
                    {/* <Link to={`/view/dispenser/${dispenser._id}/logs`}>
                      <Button type="link">View All Logs</Button>
                    </Link> */}
                  </Col>
                </Row>
                <Table
                  dataSource={dispenser.refills}
                  columns={columnsRefill}
                  pagination={false}
                />
              </div>
            )}
          </div>
          <div>
            {dispenser.restocks && (
              <div className="restock-log">
                <Row>
                  <Col flex={1}>
                    <h3 style={{ marginBottom: 12, display: "block" }}>
                      Restock Log
                    </h3>
                  </Col>
                  <Col>
                    <Link to={`/view/dispenser/${dispenser._id}/logs`}>
                      <Button type="link">View All Logs</Button>
                    </Link>
                  </Col>
                </Row>
                <Table
                  dataSource={dispenser.restocks}
                  columns={columns}
                  pagination={false}
                />
              </div>
            )}
          </div>
        </>
      )}
      <Modal
        open={showRestock}
        onCancel={closeModal}
        keyboard={false}
        maskClosable={false}
        title={`Restock ${dispenser?.name || "Dispenser"}`}
        footer={false}
      >
        <RestockForm
          id={dispenser?._id}
          name={dispenser?.name}
          closeModal={closeModal}
          fetchData={fetchData}
        />
      </Modal>
      <Modal
        title="Confirmation"
        open={open}
        onOk={handleOk}
        okButtonProps={{
          loading: confirmLoading,
          danger: true,
          type: "ghost",
        }}
        cancelButtonProps={{ disabled: confirmLoading }}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        keyboard={!confirmLoading}
        maskClosable={!confirmLoading}
      >
        <Row gutter={16}>
          <Col>
            <ExclamationCircleOutlined
              style={{ fontSize: 24, color: "#d67" }}
            />
          </Col>
          <Col flex={1}>
            <h4 style={{ marginBottom: 3 }}>
              Are you sure you want to empty this container?
            </h4>
            <p style={{ marginBottom: 0 }}>This action cannot be undone</p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default React.memo(DispenserView);
