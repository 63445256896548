import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Card, Button, Form, Input, message, Select } from "antd";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";
const { Option } = Select;

const NewCategory = () => {
  const [form] = Form.useForm();
  // const [fileListIcon, setFileListIcon] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const pagePath = [
    {
      title: "Categories",
      link: "/categories",
    },
    {
      title: id ? "Edit Category" : "Add New Category",
    },
  ];

  const onChange = () => {};

  const onFinish = (values) => {
    const temp = JSON.parse(JSON.stringify(values));
    const formData = new FormData();
    // Uncomment if adding back category icon support
    // if (fileListIcon.length) {
    //   formData.append("icon", fileListIcon[0]);
    // }
    // delete temp.icon;
    for (const name in temp) {
      if (temp[name]) {
        formData.append(name, temp[name]);
      }
    }
    if (id) {
      handleUpdate(formData);
    } else {
      handleCreate(formData);
    }
  };

  const handleCreate = (data) => {
    setSubmitting(true);
    api
      .post("category", data)
      .then(() => {
        message.success("Category created successfully");
        navigate("/categories");
      })
      .catch(() => {
        message.error("Category could not be created");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleUpdate = (data) => {
    setSubmitting(true);
    api
      .put(`category/${id}`, data)
      .then(() => {
        message.success("Category updated successfully");
        navigate("/categories");
      })
      .catch(() => {
        message.error("Category could not be updated");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (id) {
      api
        .get(`category/${id}`)
        .then((res) => {
          form.setFieldsValue({
            name: res.data.data?.name,
            icon: res.data.data.icon
              ? [
                  {
                    url: `https://api.zerotag.au/static/categories/${
                      res.data.data?.icon || ""
                    }`,
                    name: res.data.data?.name,
                    uid: 0,
                  },
                ]
              : [],
            status: res.data.data?.status,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id, form]);

  return (
    <div id="newCategory">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/categories">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="category-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
            status: true,
          }}
          onValuesChange={onChange}
          onFinish={onFinish}
        >
          <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Form.Item
                label="Category Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Category name is required",
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: "Status is required" }]}
              >
                <Select name="status" placeholder="Select one">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>

              {/* <Form.Item
                name="icon"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                label="Icon"
                // extra="Supported: .jpf, .png"
                style={{ marginBottom: 0 }}
              >
                <Upload {...propsIcon}>
                  {fileListIcon.length < 1 && (
                    <Button icon={<PictureOutlined />} />
                  )}
                </Upload>
              </Form.Item> */}
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {id ? "Update" : "Add"} Category
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(NewCategory);
