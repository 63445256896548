import React from "react";
import { Spin } from "antd";
import "./style.scss";

const Loader = () => {
  return (
    <div
      style={{
        paddingTop: 50,
        paddingBottom: 50,
        textAlign: "center",
      }}
    >
      <Spin />
      <p style={{ marginTop: 8, color: "#92929f" }}>Loading..</p>
    </div>
  );
};

export default React.memo(Loader);
