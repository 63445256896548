import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Button, Modal, Table, Space, message, Tabs } from "antd";
import { Link } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  CloseSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";
// import CategoryDetail from "./CategoryDetail";
const ROLES = ["", "Admin", "Operator", "User", "Vendor"];

const Users = () => {
  const [dataSource, setDataSource] = useState([]);
  const [roleView, setRoleView] = useState(3);
  const [deleteThis, setDeleteThis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  // useEffect(() => {
  //   console.log(dataSource);
  // }, [dataSource]);

  const pagePath = [
    {
      title: `Users (${dataSource.length})`,
    },
  ];

  const handleDelete = () => {
    setDeleting(true);
    api
      .delete(`user/${deleteThis._id}`)
      .then(() => {
        setDataSource(dataSource.filter((s) => s._id !== deleteThis._id));
        message.success(`${deleteThis?.name || "User"} deleted successfully`);
        setDeleteThis(null);
        setDeleting(false);
      })
      .catch((e) => {
        message.error(`${deleteThis?.name || "User"} could not be deleted`);
        setDeleting(false);
      });
  };
  const handleCancel = () => {
    setDeleteThis(null);
  };

  const columns = [
    // Table.EXPAND_COLUMN,
    {
      title: "Name",
      render: (cell) => (
        <div className="col1">
          {cell.status ? (
            <div className="statusGreen" />
          ) : (
            <div className="statusRed" />
          )}
          <div>{cell.name}</div>
        </div>
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (c) => ROLES[c] || "?",
    },
    // {
    //   title: "Checkout",
    //   width: 110,
    //   render: (row) =>
    //     row.role === "3" ? (
    //       <Button
    //         type="secondary"
    //         // disabled={loading || deleting}
    //         onClick={() => {
    //           setRefillThis(row._id);
    //         }}
    //       >
    //         Checkout
    //       </Button>
    //     ) : (
    //       <div>-</div>
    //     ),
    // },
    // {
    //   title: "No. of Subcategories",
    //   dataIndex: "subcategories",
    //   render: (cell) => cell?.length || 0,
    // },
    {
      title: "Actions",
      width: 110,
      render: (row) => (
        <Space>
          <Link to={`/user/${row._id}`}>
            <Button
              type="text"
              disabled={loading || deleting}
              icon={<EditOutlined style={{ color: "#66f" }} />}
            />
          </Link>
          <Button
            type="text"
            disabled={loading || deleting}
            onClick={() => {
              setDeleteThis(row);
            }}
            icon={<DeleteOutlined style={{ color: "#d66" }} />}
          />
        </Space>
      ),
    },
  ];
  useEffect(() => {
    setLoading(true);
    api
      .get("users")
      .then((res) => {
        setDataSource(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err", err);
        setLoading(false);
      });
  }, []);
  const filtered =
    roleView === 4
      ? dataSource
      : dataSource.filter((d) => d.role.toString() === roleView.toString());

  const customers = dataSource.filter((d) => d.role.toString() === "3");
  const operators = dataSource.filter((d) => d.role.toString() === "2");
  const admins = dataSource.filter((d) => d.role.toString() === "1");

  return (
    <div id="users">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/user/new">
            <Button type="primary">Add New</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Space direction="horizontal" className="userTabs">
          <Button
            type={roleView === 3 ? "primary" : "secondary"}
            onClick={() => {
              setRoleView(3);
            }}
          >
            Customers ({customers.length})
          </Button>
          <Button
            type={roleView === 2 ? "primary" : "secondary"}
            onClick={() => {
              setRoleView(2);
            }}
          >
            Retailers ({operators.length})
          </Button>
          <Button
            type={roleView === 1 ? "primary" : "secondary"}
            onClick={() => {
              setRoleView(1);
            }}
          >
            Admins ({admins.length})
          </Button>
          <Button
            type={roleView === 4 ? "primary" : "secondary"}
            onClick={() => {
              setRoleView(4);
            }}
          >
            All Users ({dataSource.length})
          </Button>
        </Space>
        <Table dataSource={filtered} columns={columns} rowKey="_id" />
      </div>
      {deleteThis && (
        <Modal
          visible={!!deleteThis}
          onOk={handleDelete}
          onCancel={handleCancel}
        >
          Are you sure you want to delete {deleteThis?.name || "this user"}?
        </Modal>
      )}
    </div>
  );
};

export default React.memo(Users);
