import "./App.scss";
import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  Home,
  Login,
  Districts,
  NewDistrict,
  Categories,
  NewCategory,
  Brands,
  NewBrand,
  Users,
  NewUser,
  Retailers,
  RetailerProducts,
  RetailerDesign,
  NewRetailer,
  Manufacturers,
  NewManufacturer,
  Products,
  ProductDesign,
  NewProduct,
  Zerotags,
  NewZerotag,
  Variants,
  NewVariant,
  Refills,
  Designs,
  NewDesign,
  AdjustDesign,
  Generate,
  Downloads,
  Dispensers,
  NewDispenser,
  RetailerView,
  DispenserView,
  DispenserLogs,
  Branches,
  NewBranch,
  Dispensersets,
  NewDispenserset,
  ChangePassword,
} from "./pages";
import { Spin } from "antd";
import { store, api } from "./lib";
import { Layout } from "./components";

const App = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchProfile = useCallback(
    (cookieValue) => {
      api
        .get("auth/profile", {
          headers: {
            Authorization: `Bearer ${cookieValue}`,
          },
        })
        .then((res) => {
          store.update((s) => {
            s.isLoggedIn = true;
            s.user = res.data?.data || null;
            s.token = cookieValue;
          });
          api.setToken(cookieValue);
        })
        .then(() => {
          setLoading(false);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            document.cookie =
              "AUTH_TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            setLoading(false);
            navigate("/login");
          }
        });
    },
    [navigate]
  );

  useEffect(() => {
    const cookieValue = document.cookie.replace(
      // eslint-disable-next-line no-useless-escape
      /(?:(?:^|.*;\s*)AUTH_TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    if (cookieValue) {
      fetchProfile(cookieValue);
    } else {
      navigate("/login");
    }
  }, [fetchProfile, navigate]);

  if (loading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
        <h3 style={{ marginTop: 8 }}>Loading</h3>
      </div>
    );
  }

  return (
    <Layout>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/view/retailer/:id" element={<RetailerView />} />
        <Route exact path="/view/dispenser/:id" element={<DispenserView />} />
        <Route
          exact
          path="/view/dispenser/:id/logs"
          element={<DispenserLogs />}
        />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/districts" element={<Districts />} />
        <Route exact path="/district/new" element={<NewDistrict />} />
        <Route exact path="/district/:id" element={<NewDistrict />} />
        <Route exact path="/categories" element={<Categories />} />
        <Route exact path="/category/new" element={<NewCategory />} />
        <Route exact path="/category/:id" element={<NewCategory />} />
        <Route exact path="/dispensers" element={<Dispensers />} />
        <Route exact path="/dispenser/new" element={<NewDispenser />} />
        <Route exact path="/dispenser/:id" element={<NewDispenser />} />
        <Route exact path="/brands" element={<Brands />} />
        <Route exact path="/brand/new" element={<NewBrand />} />
        <Route exact path="/brand/:id" element={<NewBrand />} />
        <Route exact path="/designs" element={<Designs />} />
        <Route exact path="/design/new" element={<NewDesign />} />
        <Route exact path="/design/:id" element={<NewDesign />} />
        <Route exact path="/design/adjust/:id" element={<AdjustDesign />} />
        <Route exact path="/users" element={<Users />} />
        <Route exact path="/user/new" element={<NewUser />} />
        <Route exact path="/user/:id" element={<NewUser />} />
        <Route exact path="/retailers" element={<Retailers />} />
        <Route exact path="/retailer/new" element={<NewRetailer />} />
        <Route exact path="/retailer/:id" element={<NewRetailer />} />
        <Route exact path="/retailer/design/:id" element={<RetailerDesign />} />
        <Route
          exact
          path="/retailer/products/:id"
          element={<RetailerProducts />}
        />
        <Route exact path="/branches" element={<Branches />} />
        <Route exact path="/branches/new" element={<NewBranch />} />
        <Route exact path="/branches/:id" element={<NewBranch />} />
        <Route exact path="/dispensersets" element={<Dispensersets />} />
        <Route exact path="/dispensersets/new" element={<NewDispenserset />} />
        <Route exact path="/dispensersets/:id" element={<NewDispenserset />} />
        <Route exact path="/manufacturers" element={<Manufacturers />} />
        <Route exact path="/manufacturer/new" element={<NewManufacturer />} />
        <Route exact path="/manufacturer/:id" element={<NewManufacturer />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/product/new" element={<NewProduct />} />
        <Route exact path="/product/:id" element={<NewProduct />} />
        <Route exact path="/product/design/:id" element={<ProductDesign />} />
        <Route exact path="/zerotags" element={<Zerotags />} />
        <Route exact path="/zerotag/new" element={<NewZerotag />} />
        <Route exact path="/zerotag/:id" element={<NewZerotag />} />
        <Route exact path="/variants" element={<Variants />} />
        <Route exact path="/variant/new" element={<NewVariant />} />
        <Route exact path="/variant/:id" element={<NewVariant />} />
        <Route exact path="/generate" element={<Generate />} />
        <Route exact path="/downloads" element={<Downloads />} />
        <Route exact path="/refills" element={<Refills />} />
        <Route exact path="*" element={<div>Not found.</div>} />
        <Route exact path="/account" element={<ChangePassword />} />
      </Routes>
    </Layout>
  );
};

export default App;
