import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Card, Button, Form, Input, message, Select } from "antd";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";
const { Option } = Select;

const NewBranch = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [retailers, setRetailers] = useState([]);

  const filterOptions = (inputValue, option) => {
    if (option.children) {
      return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
        0
        ? true
        : false;
    } else if (option.label) {
      return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
        ? true
        : false;
    }
  };

  const pagePath = [
    {
      title: "Branches",
      link: "/branches",
    },
    {
      title: id ? "Edit Branch" : "Add New Branch",
    },
  ];

  const onChange = () => {};

  const onFinish = (values) => {
    const formData = JSON.parse(JSON.stringify(values));
    if (id) {
      handleUpdate(formData);
    } else {
      handleCreate(formData);
    }
  };

  const handleCreate = (data) => {
    setSubmitting(true);
    api
      .post("branches", data)
      .then(() => {
        message.success("Branch created successfully");
        navigate("/branches");
      })
      .catch(() => {
        message.error("Branch could not be created");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleUpdate = (data) => {
    setSubmitting(true);
    api
      .put(`branches/${id}`, data)
      .then(() => {
        message.success("Branch updated successfully");
        navigate("/branches");
      })
      .catch(() => {
        message.error("Branch could not be updated");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (id) {
      api
        .get(`branches/${id}`)
        .then((res) => {
          form.setFieldsValue({
            name: res.data.data?.name,
            slug: res.data.data?.slug,
            retailer: res.data.data?.retailer,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id, form]);

  useEffect(() => {
    api
      .get("constants/retailers/")
      .then((res) => {
        setRetailers(res.data.data || []);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const normFile = (e) => {
    return e.fileList;
  };

  return (
    <div id="newBranch">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/branches">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="branch-form"
          form={form}
          layout="vertical"
          initialValues={{
            name: "",
            slug: "",
          }}
          onValuesChange={onChange}
          onFinish={onFinish}
        >
          <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Form.Item
                label="Branch Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Branch name is required",
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label="Slug"
                name="slug"
                placeholder="URL friendly slug"
                rules={[
                  {
                    required: true,
                    message: "Slug is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Retailer"
                name="retailer"
                rules={[
                  {
                    required: true,
                    message: "Please select a retailer",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  // mode="multiple"
                  showSearch
                  filterOption={filterOptions}
                  placeholder="Select one"
                >
                  {retailers.map((c) => (
                    <Option value={c._id} key={c._id}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {id ? "Update" : "Add"} Branch
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(NewBranch);
