import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Button, Modal, Table, Space, message } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";

const Categories = () => {
  const [dataSource, setDataSource] = useState([]);
  const [deleteThis, setDeleteThis] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const pagePath = [
    {
      title: `Categories (${dataSource.length})`,
    },
  ];

  const handleDelete = () => {
    setDeleting(true);
    api
      .delete(`category/${deleteThis._id}`)
      .then(() => {
        setDataSource(dataSource.filter((s) => s._id !== deleteThis._id));
        message.success(
          `${deleteThis?.name || "Category"} deleted successfully`
        );
        setDeleteThis(null);
        setDeleting(false);
      })
      .catch(() => {
        message.error(`${deleteThis?.name || "Category"} could not be deleted`);
        setDeleting(false);
      });
  };
  const handleCancel = () => {
    setDeleteThis(null);
  };

  const columns = [
    Table.EXPAND_COLUMN,
    // {
    //   title: "Name",
    //   key: "icon",
    //   render: (row) => (
    //     <Space direction="horizontal" size="middle">
    //       {row?.icon ? (
    //         <Avatar
    //           src={`https://api.zerotag.au/static/categories/${row?.icon}`}
    //           size={48}
    //         />
    //       ) : (
    //         <PictureOutlined className="iconPlaceholder" />
    //       )}
    //       <span>{row.name}</span>
    //     </Space>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Actions",
      width: 110,
      render: (row) => (
        <Space>
          <Link to={`/category/${row._id}`}>
            <Button
              type="text"
              disabled={loading || deleting}
              icon={<EditOutlined style={{ color: "#66f" }} />}
            />
          </Link>
          <Button
            type="text"
            disabled={loading || deleting}
            onClick={() => {
              if (row.subcategories?.length) {
                message.error(
                  `Cannot delete ${row.name} as it has subcategories`
                );
              } else {
                setDeleteThis(row);
              }
            }}
            icon={<DeleteOutlined style={{ color: "#d66" }} />}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    api
      .get("categories")
      .then((res) => {
        setDataSource(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err", err);
        setLoading(false);
      });
  }, []);

  return (
    <div id="categories">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/category/new">
            <Button type="primary">Add New</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Table dataSource={dataSource} columns={columns} rowKey="_id" />
      </div>
      {deleteThis && (
        <Modal
          visible={!!deleteThis}
          onOk={handleDelete}
          onCancel={handleCancel}
        >
          Are you sure you want to delete {deleteThis?.name || "this category"}?
        </Modal>
      )}
    </div>
  );
};

export default React.memo(Categories);
