import React, { useEffect, useState } from "react";
import "./style.scss";
import { Row, Col, Card, Button, Form, Input, message, Select } from "antd";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components";
import { api } from "../../lib";
const { Option } = Select;

const NewZerotag = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [variants, setVariants] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const pagePath = [
    {
      title: "Zerotags",
      link: "/zerotags",
    },
    {
      title: id ? "Edit Zerotag" : "Add New Zerotag",
    },
  ];

  const onChange = () => {};

  const onFinishFailed = ({ errorFields }) => {
    // console.log(value, errorFields, outOfDate);
    errorFields.map((e) => {
      e.errors.map((eI) => {
        message.error(eI);
      });
    });
  };

  const handleCreate = (values) => {
    setSubmitting(true);
    api
      .post("zerotag", values)
      .then(() => {
        message.success("Zerotag created successfully");
        navigate("/zerotags");
      })
      .catch((e) => {
        if (e.response?.data?.error?.code === 11000) {
          message.error("Zerotag code already in use");
        } else {
          message.error("Zerotag could not be created");
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleUpdate = (values) => {
    setSubmitting(true);
    api
      .put(`zerotag/${id}`, values)
      .then(() => {
        message.success("Zerotag updated successfully");
        navigate("/zerotags");
      })
      .catch(() => {
        message.error("Zerotag could not be updated");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (id) {
      api
        .get(`zerotag/${id}`)
        .then((res) => {
          if (res.data?.data) {
            form.setFieldsValue({
              code: res.data.data.code,
              variant: res.data.data.variant,
              user: res.data.data.user,
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [id, form]);

  useEffect(() => {
    Promise.all([api.get(`constants/variants`), api.get(`constants/users`)])
      .then((res) => {
        setVariants(res?.[0]?.data?.data || []);
        setUsers(res?.[1]?.data?.data || []);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <div id="zerotags">
      <Row align="bottom">
        <Col flex={1}>
          <Breadcrumbs pagePath={pagePath} />
        </Col>
        <Col>
          <Link to="/zerotags">
            <Button>Cancel</Button>
          </Link>
        </Col>
      </Row>
      <div className="body">
        <Form
          name="zerotag-form"
          form={form}
          layout="vertical"
          initialValues={{
            code: "",
            variant: null,
            user: null,
          }}
          onValuesChange={onChange}
          onFinish={id ? handleUpdate : handleCreate}
          onFinishFailed={onFinishFailed}
        >
          <Card style={{ marginBottom: 16 }}>
            <div className="form-row">
              <Form.Item
                label="Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Code is required",
                  },
                ]}
              >
                <Input autoFocus />
              </Form.Item>
              <Form.Item
                label="Variant"
                name="variant"
                rules={[
                  {
                    required: true,
                    message: "Variant is required",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  allowClear
                  showSearch
                  placeholder="Select.."
                >
                  {variants.map((c) => (
                    <Option value={c._id} key={c._id}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Assigned to"
                name="user"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  allowClear
                  showSearch
                  placeholder="Select.."
                >
                  {users.map((c) => (
                    <Option value={c._id} key={c._id}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Card>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {id ? "Update" : "Add"} Zerotag
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default React.memo(NewZerotag);
