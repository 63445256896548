import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Table, Space, message, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { api } from "../../lib";
import { Breadcrumbs } from "../../components";
import moment from "moment";

const Downloads = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const pagePath = [
    {
      title: `Downloads (${dataSource.length})`,
    },
  ];

  useEffect(() => {
    setLoading(true);
    api
      .get("batches")
      .then((res) => {
        setDataSource(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("err", err);
        setLoading(false);
      });
  }, []);

  const importBatch = (id) => {
    const data = {
      id,
    };
    setSubmitting(true);
    api
      .post("import", data)
      .then((res) => {
        if (res.data?.success) {
          message.success("QR Codes imported successfully");
          navigate("/zerotags");
        } else {
          message.error("QR Codes could not be imported");
        }
      })
      .catch((e) => {
        console.error(e);
        message.error("QR Codes could not be generated");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const columns = [
    {
      title: "Design",
      dataIndex: "design",
      render: (cell) => (
        <Space direction="horizontal">
          <Avatar
            src={`https://api.zerotag.au/static/designs/${cell.image}`}
            size={32}
            shape="square"
          />
          <p>{cell.name}</p>
        </Space>
      ),
    },
    {
      title: "Prefix",
      dataIndex: "prefix",
    },
    {
      title: "Start",
      dataIndex: "start",
    },
    {
      title: "Quantity",
      dataIndex: "qty",
    },
    {
      title: "Generated",
      dataIndex: "createdAt",
      render: (c) => moment(c).format("DD MMM YY hh:mm a"),
    },
    {
      title: "Status",
      render: (row) =>
        row.imported ? (
          <span style={{ color: "#3fa77f" }}>Imported</span>
        ) : row.status ? (
          <span style={{ color: "#5fc75f" }}>Ready</span>
        ) : (
          <span style={{ color: "#cb6e6e" }}>Processing</span>
        ),
    },
    {
      title: "Actions",
      width: 110,
      render: (row) => (
        <Space>
          {row.path && (
            <a
              href={`https://api.zerotag.au/static/generated/${row?.path}`}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                title="Download"
                disabled={loading}
                icon={<DownloadOutlined style={{ color: "#66f" }} />}
              />
            </a>
          )}
          {row.status === true && row.path && row.imported !== true && (
            <Button
              onClick={() => {
                importBatch(row._id);
              }}
              title="Import"
              disabled={loading || submitting}
              icon={<PlusOutlined style={{ color: "#66f" }} />}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div id="downloads">
      <Breadcrumbs pagePath={pagePath} />
      <div className="body">
        <Table dataSource={dataSource} columns={columns} rowKey="_id" />
      </div>
    </div>
  );
};

export default React.memo(Downloads);
