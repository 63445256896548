import QRCode from "react-qr-code";

const QREmbed = ({ value }) => {
  return (
    <div>
      {!!value && <QRCode value={`https://app.zerotag.com/square/${value}`} />}
    </div>
  );
};

export default QREmbed;
